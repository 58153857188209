import slide7 from "../../../../../assets/images/slide7.png";
import getText from "../../../../../appText/getText";
import "./Slide7.scss";

const SlideSeventh = () => {
    return (
        <div className="slide7-container">
            <div className="slide7-wrapper">
                <div className="phone-slide7">
                    <img className="phone-screen-giftBonuses" src={slide7} alt="screen-giftBonuses"/>
                </div>
                <div className="slide7-description">
                    <div className="slide7-firstDescription">
                        <h3>{getText("about_application.description.slide7.title")}</h3>
                        <p>{getText("about_application.description.slide7.parts.partOne")}</p>
                    </div>
                    <div className="slide7-secondDescription">
                        <p>{getText("about_application.description.slide7.parts.partTwo")}</p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SlideSeventh;