import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slider.scss"
import SlideSecond from "../../pages/Main/AboutApplication/Slides/Slide2/slide2";
import SlideThird from "../../pages/Main/AboutApplication/Slides/Slide3/Slide3";
import SlideFourth from "../../pages/Main/AboutApplication/Slides/Slide4/Slide4";
import SlideFifth from "../../pages/Main/AboutApplication/Slides/Slide5/Slide5";
import SlideSixth from "../../pages/Main/AboutApplication/Slides/Slide6/Slide6";
import SlideSeventh from "../../pages/Main/AboutApplication/Slides/Slide7/Slide7";
import SlideEighth from "../../pages/Main/AboutApplication/Slides/Slide8/Slide8";
import SlideNinth from "../../pages/Main/AboutApplication/Slides/Slide9/Slide9";

function Slider({ className}) {

    const settings = {
        dots: false,
        speed: 0,
        slidesToShow: 1,
        slidesToScroll: 1

    };

    return(
        <div className="sr">
            <SlickSlider className={className} {...settings}>
                <SlideSecond/>
                <SlideThird/>
                <SlideFourth/>
                <SlideFifth/>
                <SlideSixth/>
                <SlideSeventh/>
                <SlideEighth/>
                <SlideNinth/>
            </SlickSlider>
        </div>
    )
}

export default Slider