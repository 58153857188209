import React, {useEffect, useRef, useState} from "react";
import {ReactComponent as Row} from "../../assets/icons/input-row.svg"
import "./select.scss"


function Select({options, placeholder, onChange, value, required, id}) {
    const [isOpenOptions, setIsOpenOptions] = useState(false)
    const selectRef = useRef()


    useEffect(() => {
        window.addEventListener("click", (event) => {
            if (event.target.closest(".select") !== selectRef.current) {
                setIsOpenOptions(false)
            }
        })
    }, [])


    function renderOptions(options) {
        const listItems = options.map(option => {
            const onOptionClick = () => {
                onChange(option.value, option.city)
                setIsOpenOptions(false)
            }


            return <li key={option.label} className="option" onClick={onOptionClick}>{option.label}</li>
        })

        return (
            <ul className="options">
                {listItems}
            </ul>
        )
    }


    function toggleOptions() {
        setIsOpenOptions(!isOpenOptions)
    }

    return (
        <div
            ref={selectRef}
            className={isOpenOptions ? "select show-options" : "select"}
        >
            <input
                placeholder={placeholder}
                type="text"
                value={value}
                onClick={toggleOptions}
                onChange={() => {
                }}
                required={required}
                id={id}
                autocomplete={"off"}
            />
            {renderOptions(options)}
            <Row className="row" onClick={toggleOptions}/>
        </div>
    )
}

export default Select