import pages from "./text/pages";
import words from "./text/words";
import componentsText from "./text/componentsText";

const ru_text = {
   ...pages,
   words,
   ...componentsText
}

export default ru_text