import {Html, OrbitControls, PerspectiveCamera} from "@react-three/drei";
import React, {Suspense, useEffect} from "react";
import ModelCup3D from "../../../../assets/3Dmodel/Stakan27";
import {Canvas, useThree} from "@react-three/fiber";
import "./ModelCup.scss"
import getText from "../../../../appText/getText";
import CupImg from "../../../../assets/images/stakan.png"


function ModelCup () {

    function Loader() {
        return <Html className='img-cup' center><img src={CupImg}/></Html>;
    }



    return(
        <div className="model-cup-page">
            <div className="description-3d-model">
                <p>{getText("franchise.franchise_formats.format_description.cup")}</p>
            </div>
            <div className="model-cup-3d">
            <Canvas  camera={{position: [0.5, 0.5, -5], zoom: 1.8, fov: 90}}>
                <OrbitControls enableZoom={false} autoRotate={false} maxPolarAngle={2} minPolarAngle={1}/>
                <ambientLight intensity={1.2} />
                <spotLight
                    intensity={0.2}
                    angle={0.8}
                    penumbra={2}
                    position={[15, 10, 15]}
                />
                <Suspense fallback={<Loader/>}>
                    <ModelCup3D position={[0, -1.5, 0]} />
                </Suspense>
            </Canvas>
            </div>
        </div>
    )
}

export default ModelCup;