import "./SequenceOfCooperation.scss"
import graphicImage from "../../../assets/images/SequenceOfCooperationIcon.png"
import getText from "../../../appText/getText";

function SequenceOfCooperation() {
    return (
        <div className="sequence-of-cooperation-page">
            <h1>{getText("franchise.sequence_of_cooperation.title")}</h1>
                <img src={graphicImage}/>
                    <p className="first-inscription">{getText("franchise.sequence_of_cooperation.inscription.first_inscription")}</p>
                    <p className="second-inscription">{getText("franchise.sequence_of_cooperation.inscription.second_inscription")}</p>
                    <p className="third-inscription">{getText("franchise.sequence_of_cooperation.inscription.third_inscription")}</p>
                    <p className="fourth-inscription">{getText("franchise.sequence_of_cooperation.inscription.fourth_inscription")}</p>
                    <p className="fifth-inscription">{getText("franchise.sequence_of_cooperation.inscription.fifth_inscription")}</p>
                    <p className="sixth-inscription">{getText("franchise.sequence_of_cooperation.inscription.sixth_inscription")}</p>
        </div>
    )
}

export default SequenceOfCooperation;