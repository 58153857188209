import {useState} from "react";


export default function PopupState () {
    const [modalVisible, setModalVisible] = useState(false);
    const [imgClose, setImgClose] = useState(false)
    const [showResumeButton, setShowResumeButton] = useState(false)
    const [showFranchiseButton, setShowFranchiseButton] = useState(false)
    const [isCloseIcon, setIsCloseIcon] = useState(false)
    const [message, setMessage] = useState('')

    return {
        modalVisible: modalVisible,
        message: message,
        imgClose: imgClose,
        showResumeButton: showResumeButton,
        showFranchiseButton: showFranchiseButton,
        isCloseIcon: isCloseIcon,
        closeModal: () => {setModalVisible (false)},
        openModal: () => {setModalVisible (true)},
        setIsCloseIcon: boolean => setIsCloseIcon(boolean),
        setImgClose: boolean => setImgClose(boolean),
        setShowResumeButton: boolean => setShowResumeButton(boolean),
        setShowFranchiseButton: boolean => setShowFranchiseButton(boolean),
        setMessage: string => setMessage(string),
    }
}