import {useContext} from "react";
import {StoreContext} from "../../App";

function LanguageSwitcher({updateLanguage}) {
    const {store: {language}} = useContext(StoreContext)

    return (
        <div className="language-switcher">
            <span className={language === "uk" ? "active" : null} onClick={() => updateLanguage("uk")}>UK</span>
            <span className={language === "ru" ? "active" : null} onClick={() => updateLanguage("ru")}>RU</span>
        </div>
    )
}

export default LanguageSwitcher