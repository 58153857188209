import getText from "../../../appText/getText";
import Slider from "../../../components/Slider/Slider";
import GooglePlayUK from "../../../assets/images/GooglePlayUK.svg";
import GooglePlayRU from "../../../assets/images/GooglePlayRU.svg";
import AppStoreUK from "../../../assets/images/AppStoreUK.svg";
import AppStoreRU from "../../../assets/images/AppStoreRU.svg";
import "./about-mobile-app.scss"
import {useContext} from "react";
import {StoreContext} from "../../../App";

function AboutApplication() {

    const {store: {language}} = useContext(StoreContext)

    return (
        <div className="about_mobile_app">
            <h1 className="title">{getText("home.about_mobile_app")}</h1>
            <div className="buttons">
                <a href={"https://apps.apple.com/ru/app/coffee-mobile/id1572962546"} target="-_blank">
                    <img className="app-store" src={language === "uk" ? AppStoreUK : AppStoreRU} alt="app-store"/>
                </a>
                <a href={"https://play.google.com/store/apps/details?id=com.coffeemobile.app&hl=ru&gl=US"} target="-_blank">
                    <img className="google-play" src={language === "uk" ? GooglePlayUK : GooglePlayRU} alt="google-play"/>
                </a>
                </div>
            <Slider/>
        </div>
    )
}

export default AboutApplication
