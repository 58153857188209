import "./footer.scss"
import getText from "../../appText/getText";
import {withRouter} from "react-router";
import {ReactComponent as Facebook} from '../../assets/icons/facebook.svg';
import {ReactComponent as Instagram} from '../../assets/icons/instagram.svg';
import {ReactComponent as Telegram} from '../../assets/icons/telegram.svg';
import {useEffect, useState} from "react";


function Footer({history}) {

    const [isWindowSmall, setIsWindowSmall] = useState(window.innerWidth > 800)

    useEffect(() => {
        const onResize = (event) => {
            setIsWindowSmall(event.target.innerWidth > 800)
        }
        window.addEventListener("resize", onResize)

        return () => {
            window.removeEventListener("resize", onResize)
        }
    }, [])

    function renderNavItems() {
        const items = ["about_us", "about_mobile_app", "locations", "feedback", "job_application", ]

        return items.map(path => {
            return (
                <li key={path} onClick={() => history.push(`/${path}`)}>
                    {getText(`${path}`)}
                </li>
            )
        })
    }

    return (
        <div className="footer">
            <div className="container">
                {
                    isWindowSmall ?
                        <>
                            <div>
                                <ul className="nav-list">
                                    {renderNavItems()}
                                </ul>
                            </div>
                            <div className="contacts">
                                <h4 className="block-title">{getText("words.contacts")}</h4>
                                <ul>
                                    <li>{getText("words.phone")}: +38 (093) 111-00-88</li>
                                    <li>{getText("words.phone")}: +38 (067) 111-00-88</li>
                                    <li>{getText("words.mail")}: coffeemobile.ua@gmail.com</li>
                                </ul>
                            </div>
                            <div className="social-networks">
                                <h4 className="block-title">{getText("join_us")}</h4>
                                <ul className="social-networks-icons" onClick={() => {
                                    window.fbq('track', 'Contact');
                                    window.gtag('event', 'Contact');
                                }}>
                                    <li>
                                    <a href={'https://www.instagram.com/coffeemobile.ua/'} target="-_blank"><Instagram/></a>
                                    </li>
                                    <li>
                                        <a href={'https://www.facebook.com/coffeemobile.com.ua'} target="-_blank"><Facebook/></a>
                                    </li>
                                    <li>
                                        <a href={'https://t.me/coffeemobile2'} target="-_blank"><Telegram/></a>
                                    </li>
                                </ul>
                            </div>
                        </> :
                        <>
                            <div className="social-networks">
                                <h4 className="block-title">{getText("join_us")}</h4>
                                <div className="social-networks-icons" onClick={() => {
                                    window.fbq('track', 'Contact');
                                    window.gtag('event', 'Contact');
                                }}>
                                    <a href={'https://www.instagram.com/coffeemobile.ua/'} target="-_blank">
                                        <Instagram/>
                                    </a>
                                    <a href={'https://www.facebook.com/coffeemobile.com.ua'} target="-_blank">
                                        <Facebook/>
                                    </a>
                                    <a href={'https://t.me/coffeemobile2'} target="-_blank">
                                        <Telegram/>
                                    </a>
                                </div>
                            </div>
                        </>
                }
            </div>
        </div>
    )
}

export default withRouter(Footer)