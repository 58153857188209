import './aboutUs.scss';
import FirstPhoto from '../../../assets/images/first-photo.jpg'
import SecondPhoto from '../../../assets/images/second-photo.jpg'
import ThreePhoto from '../../../assets/images/three-photo.jpg'
import getText from "../../../appText/getText";
import {useContext} from "react";
import {StoreContext} from "../../../App";

function AboutUs() {

    const {store: {language}} = useContext(StoreContext)

    return (
        <div className="page-aboutUs">
            <div className="title">
            <h1>{getText('home.about_us')}</h1>
            </div>
                <section className="part-one">
                <p className="part-one-description">{getText('about_our_company.first_description')}</p>
                <img src={FirstPhoto} className="part-one-image" alt="photo of a mobile coffee shop"/>
            </section>
            <section className="part-two">
                <img src={SecondPhoto} className="part-two-image"  alt="photo of a maf coffee shop"/>
                <p className="part-two-description">{getText('about_our_company.second_description')}</p>
            </section>
            <section className="part-three">
                <div className="description-wrapper">
                <p className="part-three-description1">{getText('about_our_company.three_description.part_one')}</p>
                <p className="part-three-description2">{getText('about_our_company.three_description.part_two')}</p>
                </div>
                    <img src={ThreePhoto} className="part-three-image"  alt="photo of the mobile application"/>
            </section>
        </div>
    )
}

export default AboutUs;