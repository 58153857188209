import slide8 from "../../../../../assets/images/slide8.png";
import getText from "../../../../../appText/getText";
import "./Slide8.scss";

const SlideEighth = () => {
    return (
        <div className="slide8-container">
            <div className="slide8-wrapper">
                <div className="phone-slide8">
                    <img className="phone-screen-feedback" src={slide8} alt="screen-feedback"/>
                </div>
                <div className="slide8-description">
                    <div className="slide8-firstDescription">
                        <h3>{getText("about_application.description.slide8.title")}</h3>
                        <p>{getText("about_application.description.slide8.parts.partOne")}</p>
                        <div className="slide8-secondDescription">
                            <ul>
                                <li>{getText("about_application.description.slide8.parts.partTwo.1")}</li>
                                <li>{getText("about_application.description.slide8.parts.partTwo.2")}</li>
                                <li>{getText("about_application.description.slide8.parts.partTwo.3")}</li>
                                <li>{getText("about_application.description.slide8.parts.partTwo.4")}</li>
                                <li>{getText("about_application.description.slide8.parts.partTwo.5")}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="slide8-threeDescription">
                        <p>{getText("about_application.description.slide8.parts.partThree")}</p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SlideEighth;