import React from "react";

const ru_components_text = {
   about_mobile_app: "О мобильном приложении",
   feedback: "Оставить отзыв",
   locations: "Наши локации",
   job_application: "Подать заявку на работу",
   join_us: "Присоединяйся к нам",
   about_us: "О нас",
   download_app: "Скачивай приложение Coffee-Mobile",
   switch_theme: "Изменить тему",
   switch_language: "Изменить язык",

   header: {
      download_app: (
          <React.Fragment>Скачивай приложение <br/> Coffee-Mobile</React.Fragment>
      ),
   }
}

export default ru_components_text