import './PresentationFranchise.scss'


const PresentationFranchise = () => {
    return (
        <div className="presentation-page">
            <div className='franchise-presentation-section'>
                <iframe className='presentation-wrapper'
                        src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFhA1p_hXU&#x2F;view?embed"
                >
                </iframe>
            </div>
        </div>
    )
}

export default PresentationFranchise;