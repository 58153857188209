/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function ModelMaf3D({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('model/Maf/Maf27.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.Mesh032.geometry} material={materials.хром} />
      <mesh geometry={nodes.Mesh032_1.geometry} material={materials['Material #16']} />
      <group position={[5.12, 3.57, 7.78]} rotation={[1.14, -0.41, -0.63]} scale={0.86}>
        <spotLight intensity={0.1} angle={Math.PI / 8} penumbra={0.15} decay={2} rotation={[-Math.PI / 2, 0, 0]} />
      </group>
      <group position={[-5.09, 3.57, 7.78]} rotation={[1.84, -0.53, 0.73]} scale={0.86}>
        <spotLight intensity={0.1} angle={Math.PI / 8} penumbra={0.15} decay={2} color="#e1e1e1" rotation={[-Math.PI / 2, 0, 0]} />
      </group>
      <group position={[-5.09, 3.57, -7.79]} rotation={[2, 0.41, 2.51]} scale={0.86}>
        <spotLight intensity={0.1} angle={Math.PI / 8} penumbra={0.15} decay={2} color="#e1e1e1" rotation={[-Math.PI / 2, 0, 0]} />
      </group>
      <group position={[5.12, 3.57, -7.77]} rotation={[1.3, 0.53, -2.41]} scale={0.86}>
        <spotLight intensity={0.1} angle={Math.PI / 8} penumbra={0.15} decay={2} color="#e1e1e1" rotation={[-Math.PI / 2, 0, 0]} />
      </group>
      <mesh geometry={nodes.Plane011.geometry} material={materials['Material.017']} />
      <mesh geometry={nodes.Plane013.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane007.geometry} material={materials['Material.017']} />
      <mesh geometry={nodes.Plane025_1.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane025_2.geometry} material={materials['Material.001']} />
      <mesh geometry={nodes.Cube007.geometry} material={materials['Material.001']} />
      <mesh geometry={nodes.Cube007_1.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane029_1.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane029_2.geometry} material={materials['Material.021']} />
      <mesh geometry={nodes.Plane029_3.geometry} material={materials['Material.022']} />
      <mesh geometry={nodes.Plane034_1.geometry} material={materials['Material.002']} />
      <mesh geometry={nodes.Plane034_2.geometry} material={materials['Kitchen is a heart of home']} />
      <mesh geometry={nodes.Plane006.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane008.geometry} material={materials['Material.017']} />
      <mesh geometry={nodes.Plane002.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane009.geometry} material={materials['Material.017']} />
      <mesh geometry={nodes.Plane010.geometry} material={materials['Material.017']} />
      <mesh geometry={nodes.Plane014.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane012.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane015.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane016.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane017.geometry} material={materials['Material.017']} />
      <mesh geometry={nodes.Plane001.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane004.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane020.geometry} material={materials['Material.017']} />
      <mesh geometry={nodes.Plane018.geometry} material={materials['Material.017']} />
      <mesh geometry={nodes.Plane021.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane022.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane023.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane024.geometry} material={materials['Material.017']} />
      <mesh geometry={nodes.Plane025.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane026.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane027.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane028.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane029.geometry} material={materials['Material.017']} />
      <mesh geometry={nodes.Plane030.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane031.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane032.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane033.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane034.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane035.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane.geometry} material={materials['Material.006']} />
      <mesh geometry={nodes.Cube.geometry} material={materials.Material} />
      <mesh geometry={nodes.Mesh.geometry} material={materials['Material #43']} />
      <mesh geometry={nodes.Mesh_1.geometry} material={materials.plastik_black} />
      <mesh geometry={nodes.Object003.geometry} material={materials['Material #41']} />
      <mesh geometry={nodes.Mesh002.geometry} material={materials['Material #41']} />
      <mesh geometry={nodes.Mesh002_1.geometry} material={materials['Material.007']} />
      <mesh geometry={nodes.Mesh003.geometry} material={materials['Material #43']} />
      <mesh geometry={nodes.Mesh003_1.geometry} material={materials['Material #54 Slot #10.007']} />
      <mesh geometry={nodes.Object006.geometry} material={materials['Material #41']} />
      <mesh geometry={nodes.Object007.geometry} material={materials['Material #54 Slot #9.007']} />
      <mesh geometry={nodes.Cylinder001.geometry} material={materials['Material #41']} />
      <mesh geometry={nodes.Mesh007.geometry} material={materials['Material #43']} />
      <mesh geometry={nodes.Mesh007_1.geometry} material={materials['Material #41']} />
      <mesh geometry={nodes.Cylinder002.geometry} material={materials['Material #41']} />
      <mesh geometry={nodes.Object009.geometry} material={materials['Material #54 Slot #9.007']} />
      <mesh geometry={nodes.Mesh010.geometry} material={materials['Material #43']} />
      <mesh geometry={nodes.Mesh010_1.geometry} material={materials['Material #41']} />
      <mesh geometry={nodes.Object011.geometry} material={materials['Material #41']} />
      <mesh geometry={nodes.Cylinder003.geometry} material={materials['Material #41']} />
      <mesh geometry={nodes.Object012.geometry} material={materials['Material #54 Slot #9.007']} />
      <mesh geometry={nodes.Mesh014.geometry} material={materials['Material #43']} />
      <mesh geometry={nodes.Mesh014_1.geometry} material={materials['Material #41']} />
      <mesh geometry={nodes.Object014.geometry} material={materials['Material #41']} />
      <mesh geometry={nodes.Mesh016.geometry} material={materials['Material #43']} />
      <mesh geometry={nodes.Mesh016_1.geometry} material={materials.plastik_black} />
      <mesh geometry={nodes.Object016.geometry} material={materials['Material #41']} />
      <mesh geometry={nodes.Object017.geometry} material={materials['Material #43']} />
      <mesh geometry={nodes.Object018.geometry} material={materials['Material #43']} />
      <mesh geometry={nodes.Object019.geometry} material={materials['Material #41']} />
      <mesh geometry={nodes.Object020.geometry} material={materials['Material #41']} />
      <mesh geometry={nodes.Object021.geometry} material={materials['Material #43']} />
      <mesh geometry={nodes.Object022.geometry} material={materials['Material #43']} />
      <mesh geometry={nodes.Mesh024.geometry} material={materials['Material #43']} />
      <mesh geometry={nodes.Mesh024_1.geometry} material={materials['Material #41']} />
      <mesh geometry={nodes.Object023.geometry} material={materials['Material #41']} />
      <mesh geometry={nodes.Mesh027.geometry} material={materials['Material #70']} />
      <mesh geometry={nodes.Mesh027_1.geometry} material={materials['Material #71']} />
      <mesh geometry={nodes.Mesh028.geometry} material={materials['Material #70']} />
      <mesh geometry={nodes.Mesh028_1.geometry} material={materials['Material #71']} />
      <mesh geometry={nodes.Mesh031.geometry} material={materials.Croissant} />
      <mesh geometry={nodes.Mesh031_1.geometry} material={materials.black} />
      <mesh geometry={nodes.Mesh031_2.geometry} material={materials.Metal} />
      <mesh geometry={nodes.Circle002_1.geometry} material={materials['Material.007']} />
      <group position={[-0.8, 4.22, 1.65]} rotation={[Math.PI / 2, 0, 0]} scale={0.5}>
        <mesh geometry={nodes.Circle003_1.geometry} material={materials['Material.020']} />
        <mesh geometry={nodes.Circle003_2.geometry} material={materials['Material.008']} />
      </group>
      <mesh geometry={nodes.Object023001.geometry} material={materials['Material #41']} />
      <mesh geometry={nodes.Mesh029.geometry} material={materials['BARKHAT M 5']} />
      <mesh geometry={nodes.Mesh029_1.geometry} material={materials.BLACK} />
      <mesh geometry={nodes.element_11_1.geometry} material={materials.wire_000000000} />
      <mesh geometry={nodes.element_11_2.geometry} material={materials['Material.003']} />
      <mesh geometry={nodes.Cube001.geometry} material={materials['Material.017']} />
      <mesh geometry={nodes.Plane036.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane037.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Curve034.geometry} material={materials['SVGMat.001']} position={[-1.16, 4.04, 1.69]} rotation={[Math.PI / 2, 0, 0]} scale={3.79} />
      <mesh geometry={nodes.Curve061.geometry} material={materials.SVGMat} position={[-0.48, 4.46, 1.69]} rotation={[Math.PI / 2, 0, 0]} scale={3.79} />
    </group>
  )
}

useGLTF.preload('/Maf27.gltf')
