import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./App"
import './index.scss';
//import {Router} from "react-router";
import {BrowserRouter as Router} from "react-router-dom";
import {createRoot} from "react-dom/client";



const root = createRoot(document.getElementById('root'));
root.render(
  /*  <React.StrictMode>*/
        <Router>
            <App/>
        </Router>
   /* </React.StrictMode>,*/

);
