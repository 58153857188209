import "./ModelCar.scss"
import {Html, OrbitControls} from "@react-three/drei";
import React, {Suspense} from "react";
import {Canvas} from "@react-three/fiber";
import getText from "../../../../appText/getText";
import ModelCar3D from "../../../../assets/3Dmodel/Tavr20";
import CarImg from "../../../../assets/images/car.png"

const style = {
    width: "50px"

}

function ModelCar() {

    function Loader() {
        return <Html className='img-car' center><img src={CarImg}/></Html>;
    }

    return (
        <div className="model-car-page">
            <div className="description-model">
                <p>{getText("franchise.franchise_formats.format_description.car")}</p>
            </div>
            <div className="model-car-3d">
                <Canvas camera={{position: [16, 1, -7], zoom: 3, fov: 31}}>
                    <OrbitControls enableZoom={false} autoRotate={false} maxPolarAngle={1.7} minPolarAngle={1.2}/>
                    <ambientLight intensity={1}/>
                    <spotLight
                        intensity={1}
                        angle={0.8}
                        penumbra={2}
                        position={[5, 15, 15]}
                    />
                    <Suspense fallback={<Loader/>}>
                        <ModelCar3D position={[0, -0.5, -0.5]}/>
                    </Suspense>
                </Canvas>
            </div>

        </div>
    )
}

export default ModelCar;