import "./locations.scss"
import getText from "../../../appText/getText";
import {Link} from "react-router-dom";
import {useContext} from "react";
import {StoreContext} from "../../../App";


function Locations() {

    const {store: {language}} = useContext(StoreContext)

    return (
        <div className='locations'>
            <h1 className="title">
                {getText("home.locations")}
            </h1>
            <div className="addresses-group">
                <div className="addresses-block">
                    <h3 className="addresses-block-title">
                        {getText("our_location.city.location_uman")}
                    </h3>
                    <ul className="addresses-list">
                        <li className="addresses-list-item">
                            <a>{getText("our_location.place.uman.1")}</a>
                        </li>
                        <li className="addresses-list-item">
                            <a>{getText("our_location.place.uman.2")}</a>
                        </li>
                        <li className="addresses-list-item">
                            <a>{getText("our_location.place.uman.3")}</a>
                        </li>
                        <li className="addresses-list-item">
                            <a>{getText("our_location.place.uman.4")}</a>
                        </li>
                        <li className="addresses-list-item">
                            <a>{getText("our_location.place.uman.5")}</a>
                        </li>
                        <li className="addresses-list-item">
                            <a>{getText("our_location.place.uman.6")}</a>
                        </li>
                        <li className="addresses-list-item">
                            <a>{getText("our_location.place.uman.7")}</a>
                        </li>
                        <li className="addresses-list-item">
                            <a>{getText("our_location.place.uman.8")}</a>
                        </li>
                        <li className="addresses-list-item">
                            <a>{getText("our_location.place.uman.9")}</a>
                        </li>
                    </ul>
                </div>
                <div className="addresses-block">
                    <h3 className="addresses-block-title">
                        {getText("our_location.city.location_kropivniskiy")}
                    </h3>
                    <ul className="addresses-list">
                        <li className="addresses-list-item">
                            <a>{getText("our_location.place.kropivniskiy.1")}</a>
                        </li>
                        <li className="addresses-list-item">
                            <a>{getText("our_location.place.kropivniskiy.2")}</a>
                        </li>
                        <li className="addresses-list-item">
                            <a>{getText("our_location.place.kropivniskiy.3")}</a>
                        </li>
                        <li className="addresses-list-item">
                            <a>{getText("our_location.place.kropivniskiy.4")}</a>
                        </li>
                        <li className="addresses-list-item">
                            <a>{getText("our_location.place.kropivniskiy.5")}</a>
                        </li>
                        <li className="addresses-list-item">
                            <a>{getText("our_location.place.kropivniskiy.6")}</a>
                        </li>
                        <li className="addresses-list-item">
                            <a>{getText("our_location.place.kropivniskiy.7")}</a>
                        </li>
                        <li className="addresses-list-item">
                            <a>{getText("our_location.place.kropivniskiy.8")}</a>
                        </li>
                        <li className="addresses-list-item">
                            <a>{getText("our_location.place.kropivniskiy.9")}</a>
                        </li>
                        <li className="addresses-list-item">
                            <a>{getText("our_location.place.kropivniskiy.10")}</a>
                        </li>
                        <li className="addresses-list-item">
                            <a>{getText("our_location.place.kropivniskiy.11")}</a>
                        </li>

                    </ul>
                </div>
                <div className="addresses-block">
                    <h3 className="addresses-block-title">
                        {getText("our_location.city.location_bilaTserkva")}
                    </h3>
                    <ul className="addresses-list">
                        <li className="addresses-list-item">
                            <a>{getText("our_location.place.bilaTserkva.1")}</a>
                        </li>
                        <li className="addresses-list-item">
                            <a>{getText("our_location.place.bilaTserkva.2")}</a>
                        </li>

                    </ul>
                </div>
                <div className="button-wrapper">
                    <Link to="/franchise">
                        <button>{getText("words.franchise_page")}</button>
                    </Link>
                </div>
            </div>
            <div className="map">
                <div className="iframe-wrapper">
                    <iframe src="https://www.google.com/maps/d/embed?mid=1tX_jCkQTI08--hUv60wOZo8TciR4eb5g" width="100%"
                            height="480"/>
                </div>
            </div>
        </div>
    )
}

export default Locations



