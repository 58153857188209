import "./FranchiseAdvantage.scss"
import iconCard from "../../../assets/icons/Group 65.svg"
import iconTruck from "../../../assets/icons/Group 68.svg"
import iconPhone from "../../../assets/icons/Group 66.svg"
import iconGirl from "../../../assets/icons/Group 69.svg"
import iconLocation from "../../../assets/icons/Group 67.svg"
import iconNotebook from "../../../assets/icons/Group 70.svg"
import getText from "../../../appText/getText";

function FranchiseAdvantage() {
    return (
        <div className="franchise-advantage-page">
            <h1>{getText("franchise.franchise_advantage.title")}</h1>
            <div className="advantage-main-content">
                <div className="advantage-section">
                    <div className="advantage-item">
                        <img src={iconCard}/>
                        <p>{getText("franchise.franchise_advantage.advantage_first")}</p>
                    </div>
                    <div className="advantage-item">
                        <img src={iconTruck}/>
                        <p>{getText("franchise.franchise_advantage.advantage_second")}</p>
                    </div>
                </div>
                <div className="advantage-section">
                    <div className="advantage-item">
                        <img src={iconPhone}/>
                        <p>{getText("franchise.franchise_advantage.advantage_third")}</p>
                    </div>
                    <div className="advantage-item">
                        <img src={iconGirl}/>
                        <p>{getText("franchise.franchise_advantage.advantage_fourth")}</p>
                    </div>
                </div>
                <div className="advantage-section">
                    <div className="advantage-item">
                        <img src={iconLocation}/>
                        <p>{getText("franchise.franchise_advantage.advantage_fifth")}</p>
                    </div>
                    <div className="advantage-item">
                        <img src={iconNotebook}/>
                        <p>{getText("franchise.franchise_advantage.advantage_sixth.description")}</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default FranchiseAdvantage;