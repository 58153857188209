import "normalize.css"
import './App.scss';
import React, {createContext, useEffect, useState} from "react";
import routes from "./routes/routes";
import {getLanguage, setLanguage} from "./utils/methods";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollUp/scrollUp";
import {getTheme, setTheme} from "./utils/LocalStorageTheme";
import useQuery from "./utils/hooks/useQuery";
import Popup from "./components/Popup/Popup/Popup";
import PopupState from "./components/Popup/Popup/PopupState";
import TemporaryPopupState from "./components/Popup/TemporaryPopup/TemporaryPopupState";
import TemporaryPopup from "./components/Popup/TemporaryPopup/TemporaryPopup";
import Cookies from "js-cookie"


export const StoreContext = createContext(null)

function App(props) {

    const userLanguage = getLanguage() || "uk"
    const userTheme = getTheme() || "light";
    const query = useQuery();

    function getInitialConfig() {
        const themeFromShop = query.get('theme')
        const languageFromShop = query.get('language')

        const themes = ["light", "dark"]
        const arrLanguages = ["uk", "ru"]
        const theme = themeFromShop && themes.includes(themeFromShop) ? themeFromShop : userTheme
        const language = languageFromShop && arrLanguages.includes(languageFromShop) ? languageFromShop : userLanguage

        setLanguage(language)
        setTheme(theme)
        setStore({...store, theme, language})
    }


    const [store, setStore] = useState({
        theme: userTheme,
        language: userLanguage,
    })


    function updateLanguage(language) {
        setLanguage(language)
        setStore({...store, language: language})
    }


    function cutParameters() {   // Зануляє мову і тему які приходять з "магазину"
        const urlS = new URL(decodeURIComponent(document.location));
        const searchParamsE = urlS.searchParams;
        searchParamsE.delete("theme");
        searchParamsE.delete("language");
        window.history.pushState({}, '', urlS.toString());
    }


    function truncateURLAddress() {   //Функція яка бере url адрес і вирізає з нього параметри, що приходять з "магазину", записує параметри в куки
        let url = window.location.href

        if (url.includes("urlsave")) {
            function cutMyStrStartMax(str, char) {
                const string = str.replace(new RegExp(`.*?${char}(.*)`), '$1')
                Cookies.set("url", decodeURIComponent(string))
            }

            return cutMyStrStartMax(url, "urlsave=")
        }
    }


    const cookiesTime = +Cookies.get("time")
    let endTimeLine = cookiesTime + 300000;
    useEffect(() => {
        getInitialConfig()
        createsShippingDelay(endTimeLine - +new Date())
        cutParameters()
        truncateURLAddress()
    }, [])

    const [modalWindowContentSwitcher, setModalWindowContentSwitcher] = useState(true)
    const switcherModalWindow = PopupState(); // "Стан" для модальних вікон
    const temporaryModalWindowSwitcher = TemporaryPopupState();

    function stateSwitcher() {
        setModalWindowContentSwitcher(true);
    }

    function createsShippingDelay(time = 300000) {    // Встановлює затримку на повторне відправленя форми в модальному вікні
        setTimeout(stateSwitcher, time)
    }


    return (
        <div theme={store.theme} className="app">
            <StoreContext.Provider value={{
                store, setStore, switcherModalWindow, temporaryModalWindowSwitcher,
                modalWindowContentSwitcher, setModalWindowContentSwitcher, createsShippingDelay
            }}>
                <ScrollToTop/>
                <div className="bg">
                    <Header updateLanguage={updateLanguage}/>
                    <div className="content">
                        <div className="container">
                            {routes}
                        </div>
                    </div>
                </div>
                <Footer/>
                {switcherModalWindow.modalVisible && <Popup children={switcherModalWindow.message}/>}
                {temporaryModalWindowSwitcher.modalVisible &&
                <TemporaryPopup children={temporaryModalWindowSwitcher.message}/>}
            </StoreContext.Provider>
        </div>
    );
}

export default App;
