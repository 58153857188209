import "./job-application.scss"
import React, {useContext, useEffect, useState} from "react";
import Select from "../../../components/Select/Select";
import getText from "../../../appText/getText";
import {StoreContext} from "../../../App";
import axios from "axios";
import {IMaskInput} from "react-imask";


function JobApplication() {

    const dateSelectOptions = getText("resume.date_of_birth.date").map(date => {
        return {label: date, value: date}
    })

    const monthSelectOptions = getText("resume.date_of_birth.month").map(month => {
        return {label: month, value: month}
    })

    const yearSelectOptions = getText("resume.date_of_birth.year").map(year => {
        return {label: year, value: year}
    })


    let activitySelectOptions = [
        {label: getText("resume.activity.employment.study"), value: getText("resume.activity.employment.study")},
        {label: getText("resume.activity.employment.work"), value: getText("resume.activity.employment.work")},
        {label: getText("resume.activity.employment.unemployed"), value: getText("resume.activity.employment.unemployed")},
    ]

    const citySelectOptions = [
        {label: getText("resume.location.cities.uman"), value: getText("resume.location.cities.uman")},
        {label: getText("resume.location.cities.kropivniskiy"), value: getText("resume.location.cities.kropivniskiy")},
        {label: getText("resume.location.cities.bilaTserkva"), value: getText("resume.location.cities.bilaTserkva")},
    ]

    const periodSelectOptions = [
        {label: getText("resume.term_job.term.1"), value: getText("resume.term_job.term.1")},
        {label: getText("resume.term_job.term.2"), value: getText("resume.term_job.term.2")},
        {label: getText("resume.term_job.term.3"), value: getText("resume.term_job.term.3")},
        {label: getText("resume.term_job.term.4"), value: getText("resume.term_job.term.4")},
        {label: getText("resume.term_job.term.5"), value: getText("resume.term_job.term.5")},
    ]


    const [values, setValues] = useState({
        photo: null,
        name: '',
        number: '',
        date: '',
        month: '',
        year: '',
        sex: "Чоловіча",
        city: '',
        activity: '',
        period: '',
        experience: '',
        desired_salary: '',
        your_preferences: '',

    })

    const [isPhoto, setIsPhoto] = useState({photo: ""});
    const [errorFields, setErrorFields] = useState(
        {
            name: true, number: true, date: true, month: true, year: true,
            sex: true, city: true, activity: true, period: true, experience: true
        });


    const {switcherModalWindow, temporaryModalWindowSwitcher} = useContext(StoreContext);
    const {store: {language}} = useContext(StoreContext)

    useEffect(() => {
        setValues({
            ...values,
            date: "",
            month: "",
            year: "",
            city: "",
            activity: "",
            period: "",
        })
    }, [language])


    function setURL(input) {  // Функція для завантаження фото
        if (input.files && input.files[0]) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setIsPhoto({photo: e.target.result})
            }
            reader.readAsDataURL(input.files[0]);

            setValues({...values, photo: input.files[0]})
        }
    }

    function onImageInputChange(event) {
        setURL(event.target)
    }


    function getFromData() {  //Функія яка формує дані для надсилання через axios
        const bodyFormData = new FormData();
        const fieldName = values
        for (let key in fieldName){
            if (key === "month" || key === "year") continue
            if (key === "date"){
                let date = fieldName["date"] + " " + values["month"] + " " + values["year"];
                bodyFormData.append ("date", date)
            }else
            bodyFormData.append(key, fieldName[key])
        }
        return bodyFormData
    }


    function sendingForm () {

        const isError = checkFieldsEmptiness ();

        if (!isError){
            axios({
                method: "post",
                url: "/php/resume.php",
                data:  getFromData(),
                headers: { "Content-Type": "multipart/form-data" },
            })
                 .then(function (response) {
                     switcherModalWindow.openModal();
                     switcherModalWindow.setMessage(getText('popup.resume.successfully_sent'))
                     switcherModalWindow.setImgClose(false);
                     switcherModalWindow.setShowResumeButton(true)
                     switcherModalWindow.setShowFranchiseButton(false)
                     switcherModalWindow.setIsCloseIcon(false);
                     setValues ({ photo: null, name: '', number: '', date: '', month: '', year: '', sex: "",
                         city: '', activity: '', period: '', experience: '', desired_salary: '', your_preferences: '',
                     })
                }).catch(function (response) {
                switcherModalWindow.openModal();
                switcherModalWindow.setMessage(getText('popup.resume.error'))
                switcherModalWindow.setImgClose(true);
                switcherModalWindow.setIsCloseIcon(true);
            });
        }
    }

    function checkFieldsEmptiness (){    //  Перевіряємо чи заповнене поле і перезаписуємо "стан" помилок в залежності від заповненості.
        let isError = false
        let localObjError = {...errorFields}
        let fieldsNotCheck = ['photo', 'desired_salary', 'your_preferences'];
        for (let key in values) {
            if(fieldsNotCheck.includes(key)) continue

            if (values[key] === '') {
                localObjError[key] = false
                isError = true
                temporaryModalWindowSwitcher.openModal();
                temporaryModalWindowSwitcher.setMessage(getText('popup.temporary_popup.check_field_empty'));
                temporaryModalWindowSwitcher.setIsClassActive(true)
            }
            if (values[key] !== '') {
                localObjError[key] = true
            }
        }
        setErrorFields ({...localObjError});
        return isError
    }


    function onInputChange(value, key) {      // Функція яка записує в стан ведену користувачем інформацію в несаморобні input-и
        setValues({...values, [key]: value})
    }



    return (
<>
        <div className="job-application">
            <div className="job-application-bg">
                <h1 className="title">{getText("resume.title")}</h1>
                <form>
                    <div className="add-photo-block">
                        <label className="add-photo-button">
                            <input type="file"  onChange={onImageInputChange}/>
                            {values.photo && <img alt="avatar" src={isPhoto.photo}/>  }
                        </label>
                        <p className="description">
                            {getText("resume.description_photo")}
                        </p>
                    </div>
                    <div className={errorFields.name ? "form-item" : "form-item error"}>
                        <label htmlFor="name">{getText("resume.name.title")}</label>
                        <input
                            value={values.name}
                            onChange={evt => onInputChange(evt.target.value, "name")}
                            type="text"
                            placeholder={getText("resume.name.placeholder")}
                            id='name'
                            autocomplete={"off"}
                        />
                    </div>
                    <div className={errorFields.number ? "form-item" : "form-item error"}>
                        <label htmlFor="number">{getText("resume.phone_number")}</label>

                        <IMaskInput
                            mask={'+{38} (000) 000-00-00'}
                            value={values.number}
                            onChange={evt => onInputChange(evt.target.value, "number")}
                            type="tel"
                            placeholder="+38"
                            id='number'
                            autocomplete={"off"}
                        />
                    </div>
                    <div className="form-item">
                        <div
                            className={errorFields.date && errorFields.month && errorFields.year ? "date-of-birth" : "date-of-birth error"}>
                            <label htmlFor="date">{getText("resume.date_of_birth.title")}</label>
                            <div className={errorFields.date ? "date" : "date error"}>
                                <Select
                                    value={values.date}
                                    onChange={(value) => setValues({...values, date: value})}
                                    options={dateSelectOptions}
                                    placeholder={getText("resume.placeholder.date_birth")}
                                    id='date'
                                />
                            </div>
                            <div className={errorFields.month ? "month" : "month error"}>
                                <Select
                                    value={values.month}
                                    onChange={(value) => setValues({...values, month: value})}
                                    options={monthSelectOptions}
                                    placeholder={getText("resume.placeholder.month_birth")}
                                    id='month'
                                />
                            </div>
                            <div className={errorFields.year ? "year" : "year error"}>
                                <Select
                                    value={values.year}
                                    onChange={(value) => setValues({...values, year: value})}
                                    options={yearSelectOptions}
                                    placeholder={getText("resume.placeholder.year_birth")}
                                    id='year'
                                />
                            </div>
                        </div>
                    </div>
                    <div className={errorFields.sex ? "form-item" : "form-item error"}>
                        <label htmlFor="male">{getText("resume.sex.sex")}</label>
                        <div className="checkbox-group">
                            <div className="checkbox">
                                <label htmlFor="male">{getText("resume.sex.male")}</label>
                                <input
                                    id="male"
                                    type="radio"
                                    checked={values.sex === "Чоловіча"}
                                    onChange={() => setValues({...values, sex: "Чоловіча"})}
                                />
                            </div>
                            <div className="checkbox">
                                <label htmlFor="female">{getText("resume.sex.female")}</label>
                                <input
                                    id="female"
                                    type="radio"
                                    checked={values.sex === "Жіноча"}
                                    onChange={() => setValues({...values, sex: "Жіноча"})}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={errorFields.city ? "form-item" : "form-item error"}>
                        <label htmlFor="city">{getText("resume.location.title")}</label>
                        <Select
                            value={values.city}
                            onChange={(value) => setValues({...values, city: value})}
                            options={citySelectOptions}
                            placeholder={getText("resume.placeholder.city")}
                            id='city'
                        />
                    </div>
                    <div className={errorFields.activity ? "form-item" : "form-item error"}>
                        <label htmlFor="activity">{getText("resume.activity.title")}</label>
                        <Select
                            value={values.activity}
                            onChange={(value) => setValues({...values, activity: value})}
                            options={activitySelectOptions}
                            placeholder={getText("resume.placeholder.employment")}
                            id='activity'
                        />
                    </div>
                    <div className={errorFields.period ? "form-item" : "form-item error"}>
                        <label htmlFor="period">{getText("resume.term_job.title")}</label>
                        <Select
                            value={values.period}
                            onChange={(value) => setValues({...values, period: value})}
                            options={periodSelectOptions}
                            placeholder={getText("resume.placeholder.term")}
                            id='period'
                        />
                    </div>
                    <div className={errorFields.experience ? "form-item" : "form-item error"}>
                        <label htmlFor="experience">{getText("resume.experience.title")}</label>
                        <input
                            value={values.experience}
                            onChange={evt => onInputChange(evt.target.value, "experience")}
                            type="text"
                            placeholder={getText("resume.experience.placeholder")}
                            id='experience'
                            autocomplete={"off"}/>
                    </div>
                    <div className="form-item">
                        <label htmlFor="salary">{getText("resume.desired_salary.title")}</label>
                        <input value={values.desired_salary}
                               onChange={evt => onInputChange(evt.target.value, "desired_salary")}
                               type="text"
                               placeholder={getText("resume.desired_salary.placeholder")}
                               id='salary'
                               autocomplete={"off"}/>
                    </div>
                    <div className="form-item">
                        <label htmlFor="message">{getText("resume.your_preferences")}</label>
                        <textarea value={values.your_preferences}
                                  onChange={evt => onInputChange(evt.target.value, "your_preferences")}
                                  id="message"
                                  rows={6}/>
                    </div>
                </form>

                <button onClick={sendingForm}>{getText("words.submit")}</button>
            </div>
        </div>
</>

    )
}

export default JobApplication

