import "./FeedbackFranchise.scss"
import iconGirl from "../../../assets/icons/Group 69.svg"
import iconLetter from "../../../assets/icons/Group 95.svg"
import {Link} from "react-router-dom";
import {useState} from "react";
import PopupFeedback from "../../../components/Popup/PopupFeedback/PopupFeedback";
import getText from "../../../appText/getText";


function CallbackFranchise() {

    const [callbackPopup, setCallbackPopup] = useState(false);

    function stateSwitcher() {
        setCallbackPopup(true)
    }

    return (
        <div className="feedback-page">
            <h1 className="title-callback-page">{getText("franchise.callback_franchise.title")}</h1>
            <div className="feedback-main-content">
                <div className="feedback-button">
                    <img src={iconGirl} className="feedback-button-icon" onClick={stateSwitcher}/>
                    <p className="feedback-button-description-one"
                       onClick={stateSwitcher}>{getText("franchise.callback_franchise.first_icon_description")}</p>
                </div>
                <div className="feedback-button">
                    <Link to="/formFranchise"><img src={iconLetter} className="feedback-button-icon"/></Link>
                    <Link className="button" to="/formFranchise"><p className="feedback-button-description-two">
                        {getText("franchise.callback_franchise.second_icon_description")}</p></Link>
                </div>
                <div>
                    <Link to="/presentationFranchise"><p>Презентація франшизи</p></Link>
                </div>
            </div>
            {callbackPopup && <PopupFeedback setCallbackPopup={setCallbackPopup}/>}
        </div>
    )
}


export default CallbackFranchise;