import "./Franchise.scss"
import BannerFranchise from "./BannerFranchise/BannerFranchise";
import FranchiseFormats from "./3dModel/FranchiseFormats";
import TermsOfCooperation from "./TermsOfCooperation/TermsOfCooperation";
import FranchiseAdvantage from "./FranchiseAdvantage/FranchiseAdvantage";
import SequenceOfCooperation from "./SequenceOfCooperation/SequenceOfCooperation";
import CallbackFranchise from "./CalldbackFranchise/FeedbackFranchise"
import {useContext} from "react";
import {StoreContext} from "../../App";



function Franchise(props) {

    const {store: {language}} = useContext(StoreContext)

    return (
        <>

            <div className={`${language} franchise-container`}>
                <BannerFranchise/>
                <FranchiseFormats/>
                <TermsOfCooperation/>
                <FranchiseAdvantage/>
                <SequenceOfCooperation/>
                <CallbackFranchise/>
            </div>

        </>


    )
}

export default Franchise;