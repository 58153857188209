import uk from "./uk/uk";
import ru from "./ru/ru";
import {getLanguage} from "../utils/methods";


export const languagesData = { uk, ru }

export default function getText(path) {

   const language = getLanguage()
   const pathArray = path.split(".")
   let text = null;
   for (let i = 0; i <= pathArray.length; i++) {
      if (i === 0) {
         text =  languagesData[language]
      }else {
         text = text?.[pathArray[i - 1]]
      }
   }

   return text || "invalid text path"
}