import Switcher from "../Switcher/Switcher";
import {useContext} from "react";
import {StoreContext} from "../../App";
import {setTheme} from "../../utils/LocalStorageTheme";

function ThemeSwitcher() {
    const {store, setStore} = useContext(StoreContext)

    function onThemeModeChange(event) {
        if (event.target.checked){
            setStore({...store, theme: "dark"})
            setTheme("dark")
        }

        else {
            setStore({...store, theme: "light"})
            setTheme("light")
        }
    }

    return (
        <div className="theme-mode">
            <Switcher onChange={onThemeModeChange}/>
        </div>
    )
}

export default ThemeSwitcher