import slide4 from "../../../../../assets/images/slide4.png";
import getText from "../../../../../appText/getText";
import "./Slide4.scss";

const SlideFourth = () => {
    return (
        <div className="slide4-container">
            <div className="slide4-wrapper">
                <div className="phone-slide4">
                    <img className="phone-screen-awards" src={slide4} alt="screen-awards"/>
                </div>
                <div className="slide4-description">
                    <div className="slide4-firstDescription">
                        <h3>{getText("about_application.description.slide4.title.1")}</h3>
                        <p>{getText("about_application.description.slide4.parts.partOne")}</p>
                    </div>
                    <div className="slide4-secondDescription">
                        <h3>{getText("about_application.description.slide4.title.2")}</h3>
                        <p>{getText("about_application.description.slide4.parts.partTwo")}</p>
                        <ul>
                            <li>{getText("about_application.description.slide4.parts.partThree.1")}</li>
                            <li>{getText("about_application.description.slide4.parts.partThree.2")}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SlideFourth;