import {useContext, useEffect, useState} from "react";
import Select from "../../../components/Select/Select";
import {ReactComponent as Star} from "../../../assets/icons/star.svg";
import {ReactComponent as Rating1} from "../../../assets/icons/rating-1.svg";
import {ReactComponent as Rating2} from "../../../assets/icons/rating-2.svg";
import {ReactComponent as Rating3} from "../../../assets/icons/rating-3.svg";
import {ReactComponent as Rating4} from "../../../assets/icons/rating-4.svg";
import {ReactComponent as Rating5} from "../../../assets/icons/rating-5.svg";
import racoon from "../../../assets/images/raccoon.svg"
import "./feedback.scss"
import getText, {languagesData} from "../../../appText/getText";
import {getLanguage} from "../../../utils/methods";
import {StoreContext} from "../../../App";
import axios from "axios";


function Feedback() {

    const {switcherModalWindow, temporaryModalWindowSwitcher} = useContext(StoreContext);

    const [starsCount, setStarsCount] = useState(4);
    const [errorFields, setErrorFields] = useState(
        {
            area: true, city: true, selectedCity: true, salepoint: true, message: true
        })
    const [values, setValues] = useState({
        area: "",
        city: "",
        selectedCity: "",
        salepoint: "",
        message: "",
        rating: "4",
    });

    const {store: {language}} = useContext(StoreContext);
    useEffect(() => {
        setValues({
            ...values,
            area: "",
            city: "",
            salepoint: "",
        })
    }, [language]);


    let citySelectOptions = [
        {label: getText("leave_review.cities.uman"), value: getText("leave_review.cities.uman"), city: "uman"},
        {
            label: getText("leave_review.cities.kropivniskiy"),
            value: getText("leave_review.cities.kropivniskiy"),
            city: "kropivniskiy"
        },
        {label: getText("leave_review.cities.bilaTserkva"), value: getText("leave_review.cities.bilaTserkva"), city: "bilaTserkva"},
    ];

    const areaSelectOptions = [
        {label: getText("leave_review.areas.1"), value: getText("leave_review.areas.1")},
        {label: getText("leave_review.areas.2"), value: getText("leave_review.areas.2")},
        {label: getText("leave_review.areas.3"), value: getText("leave_review.areas.3")},
        {label: getText("leave_review.areas.4"), value: getText("leave_review.areas.4")},
        {label: getText("leave_review.areas.5"), value: getText("leave_review.areas.5")},
    ];


    function onHandleSetStarsCount(index) {
        setValues({...values, rating: index,})
        setStarsCount(index)
    }



    function renderStars() {
        const elements = []
        for (let i = 0; i < 5; i++) {
            elements[i] = (
                <li
                    onClick={() => onHandleSetStarsCount(i + 1)}
                    key={i}
                    className={i < starsCount ? "stars-list-item active" : "stars-list-item"}
                >
                    <Star/>
                </li>
            )
        }
        return elements
    };


    const salepointSelectOptions = values.city &&
        languagesData[getLanguage()].leave_review.salepoints[values.selectedCity].map(address => {
            return {label: address, value: address}
        });

    function salepointSelectOnArea() {  // Функція яка існує для виведеня input-а лише в разі коли вибрана одна із перших трьох сфер.
        if (values.area === getText('leave_review.areas.1'))
            return values.area === getText('leave_review.areas.1')
        if (values.area === getText('leave_review.areas.2'))
            return values.area === getText('leave_review.areas.2')
        if (values.area === getText('leave_review.areas.3'))
            return values.area === getText('leave_review.areas.3')
    }


    function onCitySelectChange(value1, value2) {
        setValues({...values, city: value1, selectedCity: value2})
    };



    function getFromData() {

        const bodyFormData = new FormData();
        for (let key in values) {
            if (key === "selectedCity")
                continue
            else bodyFormData.append(key, values[key]);
        }
        return bodyFormData
    }


    function checkFieldValue() {

        const isError = checkFieldsEmptiness();

        if (!isError) {
            axios({
                method: "post",
                url: "/php/response.php",
                data: getFromData(),
                headers: {"Content-Type": "multipart/form-data"},
            })
                .then(function (response) {
                    switcherModalWindow.openModal();
                    switcherModalWindow.setMessage(getText('popup.feedback.successfully'))
                    switcherModalWindow.setImgClose(true);
                    switcherModalWindow.setIsCloseIcon(true);
                    switcherModalWindow.setShowResumeButton(false)
                    setValues({...values, area: '', city: '', salepoint: '', message: '',})
                }).catch(function () {
                switcherModalWindow.openModal();
                switcherModalWindow.setImgClose(true);
                switcherModalWindow.setIsCloseIcon(true);
                switcherModalWindow.setMessage(getText('popup.feedback.error'))
            })
        }
    }

    function checkFieldsEmptiness() {
        let isError = false;
        const FieldValue = values;
        let localErrorObj = errorFields;
        for (let key in FieldValue) {
            if (FieldValue[key] === '') {
                if (key === 'salepoint' && FieldValue.area === getText("leave_review.areas.4")
                    || FieldValue.area === getText("leave_review.areas.5"))
                    continue
                else {
                    localErrorObj[key] = false;
                    temporaryModalWindowSwitcher.openModal();
                    temporaryModalWindowSwitcher.setMessage(getText('popup.temporary_popup.check_field_empty'));
                    temporaryModalWindowSwitcher.setIsClassActive(true)
                    isError = true
                }
            }
            if (FieldValue[key] !== '') {
                localErrorObj[key] = true;
            }
            setErrorFields({...localErrorObj})
        }
        return isError;
    }


    return (
        <div className="feedback">
            <div className="left-block">
                <div className="rating-block">
                    <h1 className="title">{getText("home.feedback")}</h1>
                    <ul className="stars-list">
                        {renderStars()}
                    </ul>
                    <div className="rating">
                        <Rating1 className={starsCount === 1 ? "active" : null}/>
                        <Rating2 className={starsCount === 2 ? "active" : null}/>
                        <Rating3 className={starsCount === 3 ? "active" : null}/>
                        <Rating4 className={starsCount === 4 ? "active" : null}/>
                        <Rating5 className={starsCount === 5 ? "active" : null}/>
                    </div>
                </div>
                <div className="form-block">
                    <form>
                        <div className={errorFields.area ? "form-item" : "form-item error"}>
                            <Select
                                value={values.area}
                                onChange={(value) => setValues({...values, area: value})}
                                options={areaSelectOptions}
                                placeholder={getText("words.area")}
                            />
                        </div>
                        <div className={errorFields.city ? "form-item" : "form-item error"}>
                            <Select
                                value={values.city}
                                onChange={onCitySelectChange}
                                options={citySelectOptions}
                                placeholder={getText("words.city")}
                            />
                        </div>
                        <div className={errorFields.salepoint ? "form-item" : "form-item error"}>
                            {
                                values.city && salepointSelectOnArea() &&
                                <Select
                                    value={values.salepoint}
                                    onChange={(value) => setValues({...values, salepoint: value})}
                                    options={salepointSelectOptions}
                                    placeholder={getText("leave_review.salepoint")}
                                    dependence={values.city}
                                />
                            }
                        </div>
                        <div className={errorFields.message ? "form-item" : "form-item error"}>
                        <textarea
                            value={values.message}
                            rows={6}
                            onChange={(event) => setValues({...values, message: event.target.value})}
                            placeholder={getText("words.comment")}
                        />
                        </div>
                    </form>
                </div>
                <button onClick={checkFieldValue}>{getText("words.submit")}</button>
            </div>
            <div className="right-block">
                <div className="message">
                    <div className="text-message">
                        {getText("leave_review.raccoon_phrase")}
                    </div>
                </div>
                <img className="racoon" src={racoon} alt="racoon"/>
            </div>
        </div>
    )
}

export default Feedback
