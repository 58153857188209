import slide2 from "../../../../../assets/images/slide2.png"
import getText from "../../../../../appText/getText";
import "./slide2.scss"

const SlideSecond = () => {
    return (
        <div className="slide2-container">
            <div className="slide2-wrapper">
                <div className="phone-wrapper">
                    <img className="phone-screen-bonus" src={slide2} alt="slide-bonus"/>
                </div>
                <div className="description-wrapper">
                    <div className="first-description">
                        <h3>{getText("about_application.description.slide2.title.1")}</h3>
                        <p>{getText("about_application.description.slide2.parts.partOne")}</p>
                    </div>
                    <div className="second-description">
                        <h3>{getText("about_application.description.slide2.title.2")}</h3>
                        <ul>
                            <li>{getText("about_application.description.slide2.parts.partTwo.1")}</li>
                            <li>{getText("about_application.description.slide2.parts.partTwo.2")}</li>
                            <li>{getText("about_application.description.slide2.parts.partTwo.3")}</li>
                        </ul>
                    </div>
                    <div className="three-description">
                        <h3>{getText("about_application.description.slide2.title.3")}</h3>
                        <ul>
                            <li>{getText("about_application.description.slide2.parts.partThree.1")}</li>
                            <li>{getText("about_application.description.slide2.parts.partThree.2")}</li>
                            <li>{getText("about_application.description.slide2.parts.partThree.3")}</li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    )
};

export default SlideSecond;