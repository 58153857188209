/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function ModelCup3D({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('model/Cup/stakan27.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.Cylinder_1.geometry} material={materials['Material.006']} />
      <mesh geometry={nodes.Cylinder_2.geometry} material={materials['Material.003']} />
      <mesh geometry={nodes.Cylinder_3.geometry} material={materials['Material.002']} />
      <mesh geometry={nodes.Cylinder_4.geometry} material={materials['Material.001']} />
      <mesh geometry={nodes.Cylinder_5.geometry} material={materials['Material.004']} />
      <mesh geometry={nodes.Cylinder_6.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Cylinder_7.geometry} material={materials['Material #41']} />
      <mesh geometry={nodes.Cylinder_8.geometry} material={materials['Material #43']} />
      <mesh geometry={nodes.Cylinder_9.geometry} material={materials.plastik_black} />
      <mesh geometry={nodes.Cylinder_10.geometry} material={materials['Material #54 Slot #6']} />
      <mesh geometry={nodes.Cylinder_11.geometry} material={materials['Material #54 Slot #7']} />
      <mesh geometry={nodes.Cylinder_12.geometry} material={materials['Material #54 Slot #10.002']} />
      <mesh geometry={nodes.Cylinder_13.geometry} material={materials['Material #55']} />
      <mesh geometry={nodes.Cylinder_14.geometry} material={materials['Material #70']} />
      <mesh geometry={nodes.Cylinder_15.geometry} material={materials['Material #71']} />
      <mesh geometry={nodes.Cylinder_16.geometry} material={materials['Material #43.001']} />
      <mesh geometry={nodes.Cylinder_17.geometry} material={materials.wire_008008136} />
      <mesh geometry={nodes.Cylinder_18.geometry} material={materials.SVGMat} />
      <mesh geometry={nodes.Cylinder_19.geometry} material={materials['SVGMat.001']} />
      <mesh geometry={nodes.Cylinder_20.geometry} material={materials['Material.020']} />
      <mesh geometry={nodes.Cylinder_21.geometry} material={materials['Material.008']} />
    </group>
  )
}

useGLTF.preload('/stakan27.gltf')
