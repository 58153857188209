import "./FranchiseFormats.scss"
import React, {useState} from "react";
import getText from "../../../appText/getText";
import ModelCup from "./ModelCup/ModelCup";
import ModelMaf from "./ModelMaf/ModelMaf";
import ModelCar from "./ModelCar/ModelCar";


function FranchiseFormats() {

    const [clickedTabId, setClickedTabId] = useState("maf")

    const deliveryApiInput = {
        'avtokaviarna': () => <ModelCar/>,
        'stakan': () => <ModelCup/>,
        'maf': () => <ModelMaf/>,
    }


    return (
        <div className="franchise-formats-page">
            <h1>{getText("franchise.franchise_formats.title")}</h1>
            <div className="format-selection-bg">
                <div className="format-selection">
                    <div className={clickedTabId === 'avtokaviarna' ? "active" : null}
                         onClick={() => setClickedTabId('avtokaviarna')}>
                        <div className="format">
                            <h2>{getText("franchise.franchise_formats.formats.car")}</h2>
                        </div>
                    </div>
                    <div className={clickedTabId === 'stakan' ? "active" : null}
                         onClick={() => setClickedTabId('stakan')}>
                        <div className="format">
                            <h2>{getText("franchise.franchise_formats.formats.cup")}</h2>
                        </div>
                    </div>
                    <div className={clickedTabId === 'maf' ? "active" : null} onClick={() => setClickedTabId('maf')}>
                        <div className="format">
                            <h2>{getText("franchise.franchise_formats.formats.maf")}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="model-3d">
                {deliveryApiInput[clickedTabId] && deliveryApiInput[clickedTabId](clickedTabId)}
            </div>
        </div>
    )
}

export default FranchiseFormats;