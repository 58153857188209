import "./ModelMaf.scss"
import {Html, OrbitControls} from "@react-three/drei";
import React, {Suspense} from "react";
import {Canvas} from "@react-three/fiber";
import ModelMaf3D from "../../../../assets/3Dmodel/Maf27";
import getText from "../../../../appText/getText";
import MafImg from "../../../../assets/images/maf.png";




function ModelMaf () {

    function Loader() {
        return <Html className='img-maf' center><img src={MafImg}/></Html>;
    }

    return (
        <div className="model-maf-page">
            <div className="description-model">
                <p>{getText("franchise.franchise_formats.format_description.maf")}</p>
            </div>
            <div className="model-maf-3d">
                <Canvas camera={{position: [8, 1, 15], zoom: 3, fov: 61}}>
                    <OrbitControls enableZoom={false} autoRotate={false} maxPolarAngle={2} minPolarAngle={1}/>
                    <ambientLight intensity={1} />
                    <spotLight
                        intensity={1}
                        angle={0.8}
                        penumbra={2}
                        position={[5, 15, 15]}
                    />
                    <Suspense fallback={<Loader/>}>
                        <ModelMaf3D position={[0, -2.2, 0]} />
                    </Suspense>
                </Canvas>
            </div>

        </div>
    )
}

export default ModelMaf;