const uk_words = {
   home_page: "Головна",
   franchise_page: "Франшиза",
   store_page: "Магазин",
   contacts: "Контакти",
   phone: "Телефон",
   mail: "Пошта",
   area: "Сфера",
   city: "Місто",
   comment: "Коментар",
   submit: "Надіслати",
   close: "Закрити",
}

export default uk_words