import "./Popup.scss"
import React, {useContext, useEffect} from "react";
import {StoreContext} from "../../../App";
import Close from "../../../assets/icons/close.svg";
import {Link} from "react-router-dom";


function Popup(props) {

    const {store: {language}} = useContext(StoreContext);
    const {switcherModalWindow} = useContext(StoreContext);

    useEffect(() => {
        document.body.style.overflow = 'hidden'

        return () => {
            document.body.style.overflow = ''
        }
    }, [])

    return (
        <div className="popup-container" onClick={() => {
            {switcherModalWindow.isCloseIcon && switcherModalWindow.closeModal()}
        }}>
            <div className="main-content" onClick={e => e.stopPropagation()}>
                <div>
                    {switcherModalWindow.imgClose &&
                    <img src={Close} onClick={() => {
                        switcherModalWindow.closeModal()
                    }}/>}
                </div>
                <div className="main-text">
                    {props.children}
                </div>
                {switcherModalWindow.showResumeButton && <div className="button-wrapper">
                    <Link to="/Home"><button onClick={switcherModalWindow.closeModal}>OK</button></Link>
                </div>}
                {switcherModalWindow.showFranchiseButton &&<div className="button-wrapper">
                    <Link to="/franchise"><button onClick={switcherModalWindow.closeModal}>OK</button></Link>
                </div>}
            </div>
        </div>
    )
}

export default Popup;