import slide5 from "../../../../../assets/images/slide5.png";
import getText from "../../../../../appText/getText";
import "./Slide5.scss";

const SlideFifth = () => {
    return (
        <div className="slide5-container">
            <div className="slide5-wrapper">
                <div className="phone-slide5">
                    <img className="phone-screen-cashback" src={slide5} alt="screen-cashback"/>
                </div>
                <div className="slide5-description">
                    <div className="slide5-firstDescription">
                        <h3>{getText("about_application.description.slide5.title")}</h3>
                        <p>{getText("about_application.description.slide5.parts.partOne")}</p>
                    </div>
                    <div className="slide5-secondDescription">
                        <p>{getText("about_application.description.slide5.parts.partTwo")}</p>
                    </div>
                    <div className="slide5-threeDescription">
                        <p>{getText("about_application.description.slide5.parts.partThree")}</p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SlideFifth;