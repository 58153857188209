import "./Shop.scss"
import Racoon from "../../assets/images/racoon-shop.png"
import getText from "../../appText/getText";
import {useContext} from "react";
import {StoreContext} from "../../App";


function ShopStopper () {

    const {store: {language}} = useContext(StoreContext)

    return (
        <div className="shop-stopper-page">
            <div className="main-content">
                <div className="message">
                    <p className="text-message">{getText('shop')}</p>
                </div>
                <div className="racoon">
                    <img src={Racoon}/>
                </div>
            </div>
        </div>
    )
}

export default ShopStopper;