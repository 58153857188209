import "./TemporaryPopup.scss"
import Close from "../../../assets/icons/close.svg"
import {useContext} from "react";
import {StoreContext} from "../../../App";


function TemporaryPopup (props) {

    const {temporaryModalWindowSwitcher} = useContext(StoreContext);

    function timeoutModalWindow () {
        temporaryModalWindowSwitcher.closeModal()
    }

    let timerId;

    function timer () {
        timerId = setTimeout(timeoutModalWindow, 4000);
    }
    timer ();

/*    useEffect(()=>{
        console.log(timerId)
    })

    useEffect(() => {
        return clearTimeout(timerId)
    }, [])*/

    return (
        <div className="popup-field-check-container">
            <div className={`${temporaryModalWindowSwitcher.isClassActive && "active"} main-text`}>
            {props.children}
            </div>
                <img src={Close} onClick={() => {
                    temporaryModalWindowSwitcher.closeModal()
                    clearTimeout(timerId)
                }
                }/>
        </div>
    )
}

export default TemporaryPopup;