import slide3 from "../../../../../assets/images/slide3.png";
import getText from "../../../../../appText/getText";
import "./Slide3.scss";

const SlideThird = () => {
    return (
        <div className="slide3-container">
            <div className="slide3-wrapper">
                <div className="phone-slide3">
                    <img className="phone-screen-advantage" src={slide3} alt="screen-advantage"/>
                </div>
                <div className="description-slide3">
                    <div className="title-slide3">
                        <h3>{getText("about_application.description.slide3.title")}</h3>
                    </div>
                    <div className="main-description">
                        <ul>
                            <li>{getText("about_application.description.slide3.mainContent.1")}</li>
                            <li>{getText("about_application.description.slide3.mainContent.2")}</li>
                            <li>{getText("about_application.description.slide3.mainContent.3")}</li>
                            <li>{getText("about_application.description.slide3.mainContent.4")}</li>
                            <li>{getText("about_application.description.slide3.mainContent.5")}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SlideThird;