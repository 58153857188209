import {withRouter} from "react-router";
import getText from "../../../appText/getText";
import "./Home.scss"
import {useContext, useEffect} from "react";
import {StoreContext} from "../../../App";
import AppStoreUK from "../../../assets/images/AppStoreUK.svg";
import AppStoreRU from "../../../assets/images/AppStoreRU.svg";
import GooglePlayUK from "../../../assets/images/GooglePlayUK.svg";
import GooglePlayRU from "../../../assets/images/GooglePlayRU.svg";
import Cup from "../../../assets/images/Cup.png"


const Home = ({history}) => {

    const {language} = useContext(StoreContext).store


    function renderLinks() {
        const links = ["about_us", "about_mobile_app", "locations", "feedback", "job_application"]
        const onClick = (path) => history.push(`/${path}`)
        return links.map(path => (
            <div key={path} className="link" onClick={() => onClick(path)}>
                {getText(`home.${path}`)}
            </div>
        ))
    }



    return (
        <div className="home">
            <div className="application-block-wrapper">
                <div className="first-background">
                    <div className="second-background">
                    </div>
                    <div className="three-background">
                        <div className="application-information-wrapper">
                            <p className="application-information">{getText("home_page.download_application")}</p>
                            <a href={"https://apps.apple.com/ru/app/coffee-mobile/id1572962546"} target="-_blank">
                                <img className="app-store" src={language === "uk" ? AppStoreUK : AppStoreRU}
                                     alt="AppStoreLogo"/>
                            </a>
                            <a href={"https://play.google.com/store/apps/details?id=com.coffeemobile.app&hl=ru&gl=US"} target="-_blank">
                                <img className="google-play" src={language === "uk" ? GooglePlayUK : GooglePlayRU}
                                     alt="GooglePlayLogo"/>
                            </a>
                        </div>
                    </div>
                    <div className="description-second-background">
                        <p className="company-name">COFFEE MOBILE</p>
                        <p className="slogan">{getText("home_page.slogan")}</p>
                    </div>
                    <div className="image-cup-wrapper">
                        <img className="image-cup" src={Cup} alt="image cup"/>
                    </div>
                </div>
                <div className="links">
                    {renderLinks()}
                </div>
            </div>
        </div>
    )
}

export default withRouter(Home);