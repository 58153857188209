import React from "react"

const uk_components_text = {
   about_mobile_app: "Про мобільний застосунок",
   feedback: "Залишити відгук",
   locations: "Наші локації",
   job_application: "Подати заявку на роботу",
   join_us: "Приєднуйся до нас",
   about_us: "Про нас",
   download_app: "Завантажуй додаток Coffee-Mobile",
   switch_theme: "Змінити тему",
   switch_language: "Змінити мову",

   header: {
      download_app: (
          <React.Fragment>Завантажуй застосунок <br/> Coffee-Mobile</React.Fragment>
      ),
   }
}

export default uk_components_text