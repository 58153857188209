import "./BannerFranchise.scss"
import SlickSlider from "react-slick";
import {Link} from "react-router-dom";
import getText from "../../../appText/getText";

function BannerFranchise () {

    const settings = {
        dots: false,
        autoplay: true,
        arrows: false,
        accessibility: false,
        fade: true,
        pauseOnHover: false,
        autoplaySpeed: 5000,
        speed: 800,
        slidesToShow: 1,
    }

    return (
        <div className="slider-banner">

            <Link className="button" to="/formFranchise"><button className="button">{getText("franchise.franchise_banner.button")}</button></Link>
            <SlickSlider {...settings}>
                <div className="slider-item-1">
                    <p className="title">{getText("franchise.franchise_banner.title")}</p>
                </div>
                <div className="slider-item-2">
                    <p className="title">{getText("franchise.franchise_banner.title")}</p>
                </div>
                <div className="slider-item-3">
                    <p className="title">{getText("franchise.franchise_banner.title")}</p>
                </div>
                <div className="slider-item-4">
                    <p className="title">{getText("franchise.franchise_banner.title")}</p>
                </div>
                <div className="slider-item-5">
                    <p className="title">{getText("franchise.franchise_banner.title")}</p>
                </div>
                <div className="slider-item-6">
                    <p className="title">{getText("franchise.franchise_banner.title")}</p>
                </div>
                <div className="slider-item-7">
                    <p className="title">{getText("franchise.franchise_banner.title")}</p>
                </div>
            </SlickSlider>
        </div>
    )
}


export default BannerFranchise;