/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function ModelCar3D({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('model/Car/Tavr20.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.Plane001_1.geometry} material={materials.plastik_black} />
      <mesh geometry={nodes.Plane001_2.geometry} material={materials['Material #54 Slot #10']} />
      <mesh geometry={nodes.Plane001_3.geometry} material={materials['Material.007']} />
      <mesh geometry={nodes.Plane001_4.geometry} material={materials['Material.002']} />
      <mesh geometry={nodes.Plane002_1.geometry} material={materials.Material} />
      <mesh geometry={nodes.Plane002_2.geometry} material={materials['Kitchen is a heart of home.001']} />
      <mesh geometry={nodes.Plane002_3.geometry} material={materials['Material #43']} />
      <mesh geometry={nodes.Plane002_4.geometry} material={materials['SVGMat.004']} />
      <mesh geometry={nodes.Plane002_5.geometry} material={materials['Material #54 Slot #10']} />
      <mesh geometry={nodes.Plane002_6.geometry} material={materials['Material.002']} />
      <mesh geometry={nodes.Plane002_7.geometry} material={materials['Material.003']} />
      <mesh geometry={nodes.Plane005.geometry} material={materials.Material} />
      <mesh geometry={nodes.Mesh.geometry} material={materials['Material #43']} />
      <mesh geometry={nodes.Mesh_1.geometry} material={materials.plastik_black} />
      <mesh geometry={nodes.Mesh_2.geometry} material={materials['Material #41']} />
      <mesh geometry={nodes.Mesh_3.geometry} material={materials['Material #54 Slot #10']} />
      <mesh geometry={nodes.Mesh_4.geometry} material={materials['Material #54 Slot #9.007']} />
      <mesh geometry={nodes.Mesh_5.geometry} material={materials['Material #54 Slot #10.007']} />
      <mesh geometry={nodes.Mesh_6.geometry} material={materials['Material.007']} />
      <mesh geometry={nodes.Mesh_7.geometry} material={materials['Material.007']} />
      <mesh geometry={nodes.coffee_mill__scanline001_1.geometry} material={materials['Kitchen is a heart of home.001']} />
      <mesh geometry={nodes.coffee_mill__scanline001_2.geometry} material={materials['Material #54 Slot #9.010']} />
      <mesh geometry={nodes.coffee_mill__scanline001_3.geometry} material={materials['plastik_black.001']} />
      <mesh geometry={nodes.coffee_mill__scanline001_4.geometry} material={materials['Material.001']} />
      <group position={[-0.03, 1.32, -0.49]}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={materials.Material} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={materials['SVGMat.003']} />
      </group>
      <mesh geometry={nodes.Cylinder002.geometry} material={materials['Material #54 Slot #10']} />
      <mesh geometry={nodes.Cylinder001.geometry} material={materials['Material #54 Slot #10']} />
      <mesh geometry={nodes.Curve006_1.geometry} material={materials['SVGMat.004']} />
      <mesh geometry={nodes.Curve006_2.geometry} material={materials['SVGMat.005']} />
      <mesh geometry={nodes.Curve007_1.geometry} material={materials['SVGMat.007']} />
      <mesh geometry={nodes.Curve007_2.geometry} material={materials['SVGMat.004']} />
      <mesh geometry={nodes.Curve007_3.geometry} material={materials['SVGMat.008']} />
      <mesh geometry={nodes.Curve007_4.geometry} material={materials['SVGMat.009']} />
      <mesh geometry={nodes.Curve008.geometry} material={materials['SVGMat.007']} />
      <mesh geometry={nodes.Curve008_1.geometry} material={materials['SVGMat.004']} />
      <mesh geometry={nodes.Curve008_2.geometry} material={materials['SVGMat.008']} />
      <mesh geometry={nodes.Curve008_3.geometry} material={materials['SVGMat.009']} />
      <mesh geometry={nodes.Curve009.geometry} material={materials['SVGMat.004']} />
      <mesh geometry={nodes.Curve009_1.geometry} material={materials['SVGMat.005']} />
    </group>
  )
}

useGLTF.preload('/Tavr20.gltf')
