import "./TermsOfCooperation.scss"
import getText from "../../../appText/getText";


function TermsOfCooperation () {
    return (
        <div className="terms-cooperation-page">
            <h1>{getText("franchise.terms_of_cooperation.title")}</h1>
            <div>
                <ul className="list-terms-cooperation">
                    <li className="item-terms-cooperation">{getText("franchise.terms_of_cooperation.items_list.1")}</li>
                    <li className="item-terms-cooperation">{getText("franchise.terms_of_cooperation.items_list.2")}</li>
                    <li className="item-terms-cooperation">{getText("franchise.terms_of_cooperation.items_list.3")}</li>
                    <li className="item-terms-cooperation">{getText("franchise.terms_of_cooperation.items_list.4")}</li>
                    <li className="item-terms-cooperation">{getText("franchise.terms_of_cooperation.items_list.5")}</li>
                </ul>
            </div>
        </div>
    )
}

export default TermsOfCooperation;