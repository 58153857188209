import pages from "./text/pages";
import words from "./text/words";
import componentsText from "./text/componentsText";

const uk_text = {
   ...pages,
   words,
   ...componentsText
}

export default uk_text