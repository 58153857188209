import {useState} from "react";


export default function TemporaryPopupState () {
    const [modalVisible, setModalVisible] = useState(false);
    const [isClassActive, setIsClassActive] = useState(false)
    const [message, setMessage] = useState('')

    return {
        modalVisible: modalVisible,
        message: message,
        isClassActive,
        closeModal: () => {setModalVisible (false)},
        openModal: () => {setModalVisible (true)},
        setIsClassActive: boolean => setIsClassActive (boolean),
        setMessage: string => setMessage(string),
    }
}