const ru_words = {
   home_page: "Главная",
   franchise_page: "Франшиза",
   store_page: "Магазин",
   contacts: "Контакты",
   phone: "Телефон",
   mail: "Почта",
   area: "Сфера",
   city: "Город",
   comment: "Комментарий",
   submit: "Отправить",
   close: "Закрыть",
}

export default ru_words