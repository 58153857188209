import logo_black from "../../assets/images/logo.svg"
import logo_white from "../../assets/images/logo-white.svg"
import {withRouter} from "react-router";
import getText from "../../appText/getText";
import ThemeSwitcher from "./ThemeSwitcher";
import LanguageSwitcher from "./LanguageSwitcher";
import React, {useContext, useEffect, useState} from "react";
import {ReactComponent as Instagram} from "../../assets/icons/instagram.svg";
import {ReactComponent as Facebook} from "../../assets/icons/facebook.svg";
import {ReactComponent as Telegram} from "../../assets/icons/telegram.svg";
import "./header.scss"
import GooglePlayUK from "../../assets/images/GooglePlayUK.svg";
import AppStoreUK from "../../assets/images/AppStoreUK.svg";
import GooglePlayRU from "../../assets/images/GooglePlayRU.svg";
import AppStoreRU from "../../assets/images/AppStoreRU.svg";
import Menu from "../../assets/icons/menu.svg"
import {StoreContext} from "../../App";
import Cookies from "js-cookie"


function Header({history, updateLanguage}) {
    const [isWindowSmall, setIsWindowSmall] = useState(window.innerWidth < 800)
    const [isMobileNavigationOpen, setIsMobileNavigationOpen] = useState(false)
    const [isScroll, setIsScroll] = useState(0)


    let scrolled;
    window.addEventListener('scroll', function () {   //Функція яка вмикає міні-хедер при прокруткі сторінки і встановлює затримку його ввімкнення.
            scrolled = window.pageYOffset;
            if (isScroll === 0 && scrolled > 0) {
                setTimeout(() => {
                    setIsScroll(scrolled)
                }, 100)

            }
            if (isScroll !== 0 && scrolled === 0) {
                return setIsScroll(scrolled);


            }
        }
    )

    const {store: {theme}} = useContext(StoreContext);
    const {store: {language}} = useContext(StoreContext);


    useEffect(() => {
        const onResize = (event) => {
            setIsWindowSmall(event.target.innerWidth < 800)
        }
        window.addEventListener("resize", onResize)

        return () => {
            window.removeEventListener("resize", onResize)
        }
    }, [])


    function stopScroll() {
        if (isMobileNavigationOpen) {
            document.body.style.overflow = 'hidden'
        }
        if (!isMobileNavigationOpen) {
            document.body.style.overflow = 'auto'
        }
    }

    stopScroll();


    function renderNavItems() {
        const itemsNav = ["home", "store", "franchise"]

        return itemsNav.map(path => {
            const onClick = () => history.push(`/${path}`)
            const className = history.location.pathname.includes(path) ? "active" : null
            if (path === "store") {
                return (
                    <li key={path}>
                   <span className={className}>
                     <a href={(Cookies.get("url") && Cookies.get("url").includes("?")) ?          // Умова за допомогою якої користувач повертається на той відділ "магазину", на якому був до переходу на "Головну" чи "франшизу"
                         `${Cookies.get("url")}&theme=${theme}&language=${language}`
                         :
                         `http://store.coffee-mobile.com.ua/index.php?route=common/home&theme=${theme}&language=${language}`
                     }>
                         {getText('words.store_page')}
                     </a>
                   </span>
                    </li>
                )
            } else {
                return (
                    <li key={path}>
                   <span className={className} onClick={onClick}>
                      {getText(`words.${path}_page`)}
                   </span>
                    </li>
                )
            }
        })
    }


    function renderItemsForMobileNavigation() {
        const items = ["home", "store", "franchise", "about_us", "about_mobile_app", "locations", "feedback", "job_application",]

        return items.map((path, index) => {
            const className = history.location.pathname.includes(path) ? "active" : null
            const onClick = () => {
                history.push(`/${path}`)
                setIsMobileNavigationOpen(false)
            }

            if (path === "store") {
                return (
                    <li key={path}>
                       <span className={className} onClick={() => onClick(path)}>
                         <a href={(Cookies.get("url") && Cookies.get("url").includes("?")) ?
                             `${Cookies.get("url")}${Cookies.get("url").includes("theme") ? '' : `&theme=${theme}&language=${language}`}`
                             :
                             `http://store.coffee-mobile.com.ua/index.php?route=common/home&theme=${theme}&language=${language}`
                         }>
                             <strong>{getText(`words.store_page`)}</strong>
                       </a>
                         </span>
                    </li>
                )
            }

            if (index < 3) {
                return (
                    <li key={path}>
                       <span className={className} onClick={() => onClick(path)}>
                          <strong>{getText(`words.${path}_page`)}</strong>
                       </span>
                    </li>
                )
            } else {
                return (
                    <li key={path} onClick={() => onClick(`/${path}`)}>
                        <span className={className}>{getText(`${path}`)}</span>
                    </li>
                )
            }

        })
    }

    function closeMobileNavigation() {
        setIsMobileNavigationOpen(false)
    }

    function openMobileNavigation() {
        setIsMobileNavigationOpen(true)
    }


    return (
        <div className="header">
            <div className="container">
                {
                    !isWindowSmall ?
                        <>
                            <div className={isScroll === 0 ? "header-wrapper" : "header-wrapper min"}>
                                <div className="logo">
                                    <img src={theme === "light" ? logo_black : logo_white} alt="Logo"/>
                                </div>
                                <nav className="menu">
                                    <ul>
                                        {renderNavItems()}
                                    </ul>
                                </nav>
                                <div className="right-block">
                                    <LanguageSwitcher updateLanguage={updateLanguage}/>
                                    <ThemeSwitcher/>
                                </div>
                            </div>
                        </> :
                        <>
                            <div className="button-menu-wrapper" onClick={openMobileNavigation}>
                                <svg width="35" height="35" viewBox="0 0 35 35" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4.375 26.25H30.625V23.3333H4.375V26.25ZM4.375 18.9583H30.625V16.0417H4.375V18.9583ZM4.375 8.75V11.6667H30.625V8.75H4.375Z"/>
                                </svg>
                            </div>
                            <div
                                onClick={closeMobileNavigation}
                                className={isMobileNavigationOpen ? "white-bg active" : "white-bg"}
                            />
                            <div className={isMobileNavigationOpen ? "navigation open" : "navigation"}>
                                <button className="close-nav-btn" onClick={closeMobileNavigation}>
                                    {getText("words.close")}
                                </button>
                                <nav className="menu">
                                    <ul>
                                        {renderItemsForMobileNavigation()}
                                    </ul>
                                </nav>
                                <div className="switch-theme">
                                    <span>{getText("switch_theme")}</span>
                                    <ThemeSwitcher/>
                                </div>
                                <div className="switch-language">
                                    <span>{getText("switch_language")}</span>
                                    <LanguageSwitcher updateLanguage={updateLanguage}/>
                                </div>
                                <div className="social-networks">
                                    <h4 className="block-title">{getText("join_us")}</h4>
                                    <div className="social-networks-icons" onClick={() => {
                                        window.fbq('track', 'Contact');
                                        window.gtag('event', 'Contact');
                                    }}>
                                        <a href={'https://www.instagram.com/coffeemobile.ua/'} target="-_blank">
                                            <Instagram/>
                                        </a>
                                        <a href={'https://www.facebook.com/coffeemobile.com.ua'} target="-_blank">
                                            <Facebook/>
                                        </a>
                                        <a href={'https://t.me/coffeemobile2'} target="-_blank">
                                            <Telegram/>
                                        </a>
                                    </div>
                                </div>
                                <div className="about-mobile-app">
                                    <h4 className="block-title">{getText("header.download_app")}</h4>
                                    <a href={"https://apps.apple.com/ru/app/coffee-mobile/id1572962546"} target="-_blank">
                                        <img className="app-store" src={language === "uk" ? AppStoreUK : AppStoreRU}
                                             alt="app-store"/>
                                    </a>
                                    <a href={"https://play.google.com/store/apps/details?id=com.coffeemobile.app&hl=ru&gl=US"} target="-_blank">
                                        <img className="google-play"
                                             src={language === "uk" ? GooglePlayUK : GooglePlayRU} alt="google-play"/>
                                    </a>
                                </div>
                                <div className="contacts">
                                    <h4 className="block-title">{getText("words.contacts")}</h4>
                                    <ul>
                                        <li>{getText("words.phone")}: (093) 111-00-88</li>
                                        <li>{getText("words.phone")}: (067) 111-00-88</li>
                                        <li>{getText("words.mail")}: coffeemobile.ua@gmail.com</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="logo">
                                <img src={theme === "light" ? logo_black : logo_white} alt="Logo"/>
                            </div>
                        </>
                }
            </div>
        </div>
    )
}

export default withRouter(Header);
