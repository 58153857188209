import "./switcher.scss"
import {getTheme} from "../../utils/LocalStorageTheme";

function Switcher({onChange}) {
   const isChecked = getTheme() === 'dark'


    return (
      <label className="switch">
         <input type="checkbox" onChange={onChange} checked={isChecked}/>
         <span className="slider"/>
      </label>
   )
}

export default Switcher