import slide9 from "../../../../../assets/images/slide9.png";
import getText from "../../../../../appText/getText";
import "./Slide9.scss";

const SlideNinth = () => {
    return (
        <div className="slide9-container">
            <div className="slide9-wrapper">
                <div className="phone-slide9">
                    <img className="phone-screen-design" src={slide9} alt="screen-design"/>
                </div>
                <div className="slide9-description">
                    <h3>{getText("about_application.description.slide9.title")}</h3>
                    <p>{getText("about_application.description.slide9.parts.partOne")}</p>
                    <ul>
                        <li>{getText("about_application.description.slide9.parts.partTwo.1")}</li>
                        <li>{getText("about_application.description.slide9.parts.partTwo.2")}</li>
                        <li>{getText("about_application.description.slide9.parts.partTwo.3")}</li>
                    </ul>
                </div>
            </div>
        </div>
    )
};

export default SlideNinth;