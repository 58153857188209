import "./PopupFeedback.scss"
import getText from "../../../appText/getText";
import {IMaskInput} from "react-imask";
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import Close from "../../../assets/icons/close.svg"
import {StoreContext} from "../../../App";
import Cookies from "js-cookie"


function PopupFeedback(props) {

    const {temporaryModalWindowSwitcher, modalWindowContentSwitcher, setModalWindowContentSwitcher, createsShippingDelay} = useContext(StoreContext);

    const [values, setValues] = useState({
        number: "",
        name: "",
    });
    const [isFieldEmpty, setIsFieldEmpty] = useState({
        number: false,
        name: false,
    });




    function getFromData() {
        const bodyFormData = new FormData();
        for (let key in values) {
            bodyFormData.append(key, values[key])
        }
        return bodyFormData
    }



    function formSubmission() {
        const isError = checkFieldsEmptiness();

        if (!isError){
            axios({
                method: "post",
                url: "/php/callback.php",
                data:  getFromData(),
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(function (response) {
                    props.setCallbackPopup(false)
                    temporaryModalWindowSwitcher.openModal();
                    temporaryModalWindowSwitcher.setMessage(getText('popup.temporary_popup.good_result'));
                    temporaryModalWindowSwitcher.setIsClassActive(false)
                    Cookies.set("time",+new Date())
                    setModalWindowContentSwitcher(false)
                    createsShippingDelay()
                    setValues ({ name: '', number: ''})
                    window.fbq('track', 'Lead');
                    window.gtag('event', 'Lead');
                }).catch(function (response) {
                temporaryModalWindowSwitcher.openModal();
                temporaryModalWindowSwitcher.setMessage(getText('popup.temporary_popup.failed_result'));
                temporaryModalWindowSwitcher.setIsClassActive(false)
            });
        }

    }


    function checkFieldsEmptiness() {
        let isError = false;
        let localErrorObj = {...isFieldEmpty}
        for (let key in values) {
            if (values[key] === '') {
                localErrorObj[key] = true
                isError = true
                temporaryModalWindowSwitcher.openModal();
                temporaryModalWindowSwitcher.setMessage(getText('popup.temporary_popup.check_field_empty'));
                temporaryModalWindowSwitcher.setIsClassActive(true)
            }
           else if (values[key] !== '') {
                localErrorObj[key] = false
            }
        }
        setIsFieldEmpty ({...localErrorObj});

        return isError;
    }


    function onInputChange(value, key) {
        setValues({...values, [key]: value})
    }


    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = ''
        }
    }, [])


    return (
        <div className="popup-feedback">
            <div className="popup-main-content">
                {
                    modalWindowContentSwitcher ?
                    <>
                    <img src={Close} className="close-icon-1" onClick={() => {
                        props.setCallbackPopup(false)
                    }}/>
                    <h1 >{getText("popup.franchise_callback.title")}</h1>
                    <div className="input-content">
                    <div className={isFieldEmpty.number ? "form-item error" : "form-item"}>
                    <IMaskInput
                    className="input-item"
                    mask={'+{38} (000) 000-00-00'}
                    value={values.number}
                    onChange={evt => onInputChange(evt.target.value, "number")}
                    type="tel"
                    id='number'
                    autocomplete={"off"}
                    placeholder="+38"
                    />
                    <label htmlFor="number" className="label-item">{getText("popup.franchise_callback.first_label")}</label>
                    </div>
                    <div className={isFieldEmpty.name ? "form-item error" : "form-item"}>
                    <input
                    className="input-item"
                    value={values.name}
                    onChange={evt => onInputChange(evt.target.value, "name")}
                    type="text"
                    id='name'
                    autoComplete={"off"}
                    placeholder="Іван"
                    />
                    <label htmlFor="name" className="label-item">{getText("popup.franchise_callback.second_label")}</label>
                    </div>
                    </div>
                    <button className="popup-button" onClick={formSubmission}>{getText("popup.franchise_callback.button")}</button>
                    </> :
                    <>
                        <img src={Close} className="close-icon-2" onClick={() => {
                            props.setCallbackPopup(false)
                        }}/>
                        <p className="main-inscription">{getText("popup.franchise_callback.waiting_window")}</p>
                    </>
                }
            </div>
        </div>
    )
}


export default PopupFeedback;