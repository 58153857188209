import "./franchiseOpeningForm.scss"
import getText from "../../../appText/getText";
import {IMaskInput} from "react-imask";
import Select from "../../../components/Select/Select";
import React, {useContext, useEffect, useState} from "react";
import {StoreContext} from "../../../App";
import axios from "axios";
import validator from 'validator'


function FranchiseOpeningForm() {

    const selectorByDay = getText("resume.date_of_birth.date").map(date => {
        return {label: date, value: date}
    })

    const selectorByMonth = getText("resume.date_of_birth.month").map(month => {
        return {label: month, value: month}
    })

    const selectorByYear = getText("resume.date_of_birth.year").map(year => {
        return {label: year, value: year}
    })


    const [fieldValues, setFieldValues] = useState({
        name: "", number: "", mail: "", date: "", month: "", year: "", sex: "Чоловіча", city_residence: "", city_work: "",
        other_franchises: "", experience_area_services: "", learned_information: "", desired_budget: "",
    });
    const [errorEmptyField, setErrorEmptyField] = useState({
        name: true,
        number: true,
        mail: true,
        date: true,
        month: true,
        year: true,
        sex: true,
        city_residence: true,
        city_work: true,
        other_franchises: true,
        experience_area_services: true,
        learned_information: true,
        desired_budget: true,
    })


    function onInputChange(value, key) {
        setFieldValues({...fieldValues, [key]: value})
    }


    const {switcherModalWindow, temporaryModalWindowSwitcher} = useContext(StoreContext);
    const {store: {language}} = useContext(StoreContext)

    useEffect(() => {
        setFieldValues({
            ...fieldValues,
            date: "",
            month: "",
            year: "",
        })
    }, [language])


    function sendingForm() {
        const isError = auditFieldsEmptiness();
        const checkEmail = fieldCheckEmail();

        if (checkEmail && isError) {
            axios({
                method: "post",
                url: "/php/franchise.php",
                data: getFromData(),
                headers: {"Content-Type": "multipart/form-data"},
            })
                .then(function (response) {
                    switcherModalWindow.openModal();
                    switcherModalWindow.setMessage(getText('popup.franchise_form.successfully'))
                    switcherModalWindow.setImgClose(false);
                    switcherModalWindow.setShowFranchiseButton(true)
                    switcherModalWindow.setShowResumeButton(false)
                    switcherModalWindow.setIsCloseIcon(false);
                    setFieldValues({
                        name: "", number: "", mail: "", date: "", month: "", year: "", sex: "",
                        city_residence: "", city_work: "", other_franchises: "", experience_area_services: "",
                        learned_information: "", desired_budget: "",
                    });
                    window.fbq('track', 'CompleteRegistration');
                    window.gtag('event', 'CompleteRegistration');
                }).catch(function (response) {
                switcherModalWindow.openModal();
                switcherModalWindow.setMessage(getText('popup.franchise_form.error'))
                switcherModalWindow.setImgClose(true);
                switcherModalWindow.setIsCloseIcon(true);
            });
        }
    }

    function auditFieldsEmptiness() {
        let isError = true,
            localFieldValues = fieldValues,
            localObjError = errorEmptyField
        for (let key in localFieldValues) {

            if (localFieldValues[key] === '') {
                localObjError[key] = false
                isError = false
                temporaryModalWindowSwitcher.openModal();
                temporaryModalWindowSwitcher.setMessage(getText('popup.temporary_popup.check_field_empty'));
                temporaryModalWindowSwitcher.setIsClassActive(true)

            }
            if (localFieldValues[key] !== '') {
                localObjError[key] = true
            }
            setErrorEmptyField({...localObjError})

        }
        return isError
    }



        function fieldCheckEmail() {
            let validatorEmail = validator.isEmail(fieldValues.mail)
            let valueEmailField = fieldValues.mail
            let errorEmailField = errorEmptyField.mail
            if (!validatorEmail && valueEmailField !== "" && auditFieldsEmptiness()) {
                errorEmailField = false
                temporaryModalWindowSwitcher.openModal();
                temporaryModalWindowSwitcher.setMessage(getText('popup.temporary_popup.Invalid_email'));
                temporaryModalWindowSwitcher.setIsClassActive(false)
            }
            setErrorEmptyField({...errorEmptyField, mail: errorEmailField})
            return validatorEmail
        }





    function getFromData() {
        const bodyFormData = new FormData;
        const fieldName = fieldValues;
        for (let key in fieldName) {
            if (key === "month" || key === "year") continue
            if (key === "date") {
                let date = fieldName["date"] + " " + fieldValues["month"] + " " + fieldValues["year"];
                bodyFormData.append("date", date)
            } else
                bodyFormData.append(key, fieldName[key])
        }
        return bodyFormData
    }


    return (
        <>
            <div className="all-page">
                <div className="form">
                    <h1 className="title-form">{getText("franchise.franchise_form.title")}</h1>
                    <form>
                        <div className={errorEmptyField.name ? "form-cell" : "form-cell error"}>
                            <label htmlFor="name">{getText("franchise.franchise_form.name")}</label>
                            <input
                                value={fieldValues.name}
                                onChange={evt => onInputChange(evt.target.value, "name")}
                                type="text"
                                id='name'
                                autocomplete={"off"}/>
                        </div>
                        <div className={errorEmptyField.number ? "form-cell" : "form-cell error"}>
                            <label htmlFor="phone">{getText("franchise.franchise_form.number_tel")}</label>
                            <IMaskInput
                                mask={'+{38} (000) 000-00-00'}
                                value={fieldValues.number}
                                onChange={evt => onInputChange(evt.target.value, "number")}
                                type="tel"
                                placeholder="+38"
                                id='number'
                                autocomplete={"off"}
                            />
                        </div>
                        <div className={errorEmptyField.mail ? "form-cell" : "form-cell error"}>
                            <label htmlFor="mail">{getText("franchise.franchise_form.email")}</label>
                            <input
                                value={fieldValues.mail}
                                onChange={evt => onInputChange(evt.target.value, "mail")}
                                type="email"
                                id="mail"
                                autocomplete={"off"}
                            />
                        </div>
                        <div className="form-cell">
                            <div className={errorEmptyField.date && errorEmptyField.month && errorEmptyField.year ? "number-of-birth" : "number-of-birth error"}>
                                <label htmlFor="date">{getText("franchise.franchise_form.number_of_birth.label")}</label>
                                <div className={errorEmptyField.date ? "day" : "day error"}>
                                    <Select
                                        value={fieldValues.date}
                                        onChange={(value) => setFieldValues({...fieldValues, date: value})}
                                        options={selectorByDay}
                                        placeholder="15"
                                        id='date'
                                    />
                                </div>
                                <div className={errorEmptyField.month ? "month" : "month error"}>
                                    <Select
                                        value={fieldValues.month}
                                        onChange={(value) => setFieldValues({...fieldValues, month: value})}
                                        options={selectorByMonth}
                                        placeholder={getText("franchise.franchise_form.number_of_birth.placeholder_month")}
                                        id='month'
                                    />
                                </div>
                                <div className={errorEmptyField.year ? "year" : "year error"}>
                                    <Select
                                        value={fieldValues.year}
                                        onChange={(value) => setFieldValues({...fieldValues, year: value})}
                                        options={selectorByYear}
                                        placeholder="2021"
                                        id='year'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={errorEmptyField.sex ? "form-cell" : "form-cell error"}>
                            <label htmlFor="sex">{getText("franchise.franchise_form.sex.label")}</label>
                            <div className="checkbox-form-group">
                                <div className="checkbox-item">
                                    <label htmlFor="male">{getText("franchise.franchise_form.sex.male")}</label>
                                    <input
                                        id="male"
                                        type="radio"
                                        checked={fieldValues.sex === "Чоловіча"}
                                        onChange={() => setFieldValues({...fieldValues, sex: "Чоловіча"})}
                                    />
                                </div>
                                <div className="checkbox-item">
                                    <label htmlFor="female">{getText("franchise.franchise_form.sex.female")}</label>
                                    <input
                                        id="female"
                                        type="radio"
                                        checked={fieldValues.sex === "Жіноча"}
                                        onChange={() => setFieldValues({...fieldValues, sex: "Жіноча"})}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={errorEmptyField.city_residence ? "form-cell" : "form-cell error"}>
                            <label htmlFor="city" className="smaller-margin">{getText("franchise.franchise_form.city_residence")}</label>
                            <input
                                value={fieldValues.city_residence}
                                onChange={evt => onInputChange(evt.target.value, "city_residence")}
                                type="text"
                                id='city'
                                autoComplete={"off"}/>
                        </div>
                        <div className={errorEmptyField.city_work ? "form-cell" : "form-cell error"}>
                            <label htmlFor="city_work" className="smaller-margin">{getText("franchise.franchise_form.city_franchise")}</label>
                            <input
                                value={fieldValues.city_work}
                                onChange={evt => onInputChange(evt.target.value, "city_work")}
                                type="text"
                                id='city_work'
                                autoComplete={"off"}
                            />
                        </div>
                        <div className={errorEmptyField.other_franchises ? "form-cell" : "form-cell error"}>
                            <label htmlFor="other_franchises" className="smaller-margin">{getText("franchise.franchise_form.other_franchises")}</label>
                            <input
                                value={fieldValues.other_franchises}
                                onChange={evt => onInputChange(evt.target.value, "other_franchises")}
                                type="text"
                                id='other_franchises'
                                autoComplete={"off"}
                            />
                        </div>
                        <div className={errorEmptyField.experience_area_services ? "form-cell" : "form-cell error"}>
                            <label htmlFor="experience" className="smaller-margin">{getText("franchise.franchise_form.experience_area_services")}</label>
                            <input
                                value={fieldValues.experience_area_services}
                                onChange={evt => onInputChange(evt.target.value, "experience_area_services")}
                                type="text"
                                id='experience'
                                autoComplete={"off"}
                            />
                        </div>
                        <div className={errorEmptyField.learned_information ? "form-cell" : "form-cell error"}>
                            <label htmlFor="learned_information" className="smaller-margin">{getText("franchise.franchise_form.learned_information")}</label>
                            <input
                                value={fieldValues.learned_information}
                                onChange={evt => onInputChange(evt.target.value, "learned_information")}
                                type="text"
                                id='learned_information'
                                autoComplete={"off"}
                            />
                        </div>
                        <div className={errorEmptyField.desired_budget ? "form-cell" : "form-cell error"}>
                            <label htmlFor="desired_budget" className="smaller-margin">{getText("franchise.franchise_form.desired_budget")}</label>
                            <input
                                value={fieldValues.desired_budget}
                                onChange={evt => onInputChange(evt.target.value, "desired_budget")}
                                type="text"
                                id='desired_budget'
                                autoComplete={"off"}
                            />
                        </div>
                    </form>
                    <button className="button" onClick={sendingForm}>{getText("words.submit")}</button>
                </div>
            </div>
        </>
    )
}

export default FranchiseOpeningForm;