import slide6 from "../../../../../assets/images/slide6.png";
import getText from "../../../../../appText/getText";
import "./Slide6.scss";

const SlideSixth = () => {
    return (
        <div className="slide6-container">
            <div className="slide6-wrapper">
                <div className="phone-slide6">
                    <img className="phone-screen-statistic" src={slide6} alt="screen-statistic"/>
                </div>
                <div className="slide6-description">
                    <div className="slide6-firstDescription">
                        <h3>{getText("about_application.description.slide6.title")}</h3>
                        <p>{getText("about_application.description.slide6.parts.partOne")}</p>
                    </div>
                    <div className="slide6-secondDescription">
                        <p>{getText("about_application.description.slide6.parts.partTwo")}</p>
                        <ul>
                            <li>{getText("about_application.description.slide6.parts.partThree.1")}</li>
                            <li>{getText("about_application.description.slide6.parts.partThree.2")}</li>
                            <li>{getText("about_application.description.slide6.parts.partThree.3")}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SlideSixth;